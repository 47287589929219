/* eslint-disable no-unused-vars */
import SimpleTable from '@components/simple-table/simple-table.component'
import { useContactListService } from './contact-list.service'
import useOrganisationDetail from '../../organisation-details.service'
import { IContactList } from '../../organisation-details.interface'
import { contactListHeaders } from '../../organisation-details.static'
import { FormikProvider } from 'formik'
import Button from '@components/button/button.component'
import FormToggleSwitch from '@components/form-toggle-switch/form-toggle-switch.component'
import ContactListForm from './contact-list-form.component'
import CreateOrEditContactModal from '../create-or-edit-contact-modal.component'
import { useModal } from '@components/modal/modal.service'
import RemoveModal from '../remove-modal.component'
import DuplicateModal from '../duplicate-modal.component'

const ContactList = () => {
    const {
        contactListData: data,
        handleContactDetail,
        contactDetail,
        formikOrgContactDetail,
        formikOrgCreateContact,
        deleteContact,
        handleDuplicate,
    } = useContactListService()

    const { removeContactModalService: removeModalService } =
        useOrganisationDetail()

    const createOrEditContactModal = useModal()
    const duplicateModal = useModal()

    return (
        <FormikProvider value={formikOrgContactDetail}>
            <div className="flex gap-x-2">
                <div className="w-3/4 h-[calc(100vh-15.5rem)]">
                    <SimpleTable<IContactList>
                        headers={contactListHeaders}
                        data={data || []}
                        addRowLabel="Add Contact"
                        isAddRow={true}
                        variant="gray"
                        onRowClick={(data) => handleContactDetail(data)}
                        customBuildParam4={() => ({
                            // set details
                            func1: () => {
                                // setIsCreateAddress(false)
                                createOrEditContactModal.openModalHandling()
                            },
                            func2: () => {
                                removeModalService.openModalHandling()
                            },
                        })}
                        addRowClick={() => {
                            createOrEditContactModal.openModalHandling()
                        }}
                    />
                </div>
                <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                    <div className="flex justify-between items-center sm:flex-col">
                        <div className="p-4">Contact Details</div>
                        <div className="m-2 flex">
                            <i
                                onClick={() => {
                                    duplicateModal.openModalHandling()
                                }}
                                className="border-l-2 cursor-pointer p-1 ri-file-copy-line text-[1.25rem]"
                            ></i>
                            <i
                                onClick={() => {
                                    removeModalService.openModalHandling()
                                }}
                                className="border-l-2 cursor-pointer p-1 ri-delete-bin-line text-[1.25rem] text-red-600"
                            ></i>
                        </div>
                    </div>
                    <div className="border-b"></div>
                    <div className="p-3 overflow-auto">
                        <div className="flex flex-col gap-3">
                            <FormToggleSwitch
                                label={'Main Address'}
                                name="is_main"
                            />

                            <ContactListForm />
                        </div>
                        <div className="flex flex-col gap-2">
                            <Button
                                label="SAVE CHANGES"
                                variant="brand-inverse"
                                className="w-full"
                                icon="ri-save-line"
                                onClick={() => {
                                    formikOrgContactDetail.submitForm()
                                }}
                            />
                            <Button
                                label="DISCARD CHANGES"
                                variant="red-inverse"
                                className="w-full"
                                icon="ri-save-line"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>

                <RemoveModal
                    modalService={removeModalService}
                    type="removeContact"
                    onClick={() => {
                        const id = contactDetail?.code || ''
                        deleteContact(id)
                    }}
                    data={contactDetail?.name || ''}
                />
                <DuplicateModal
                    modalService={duplicateModal}
                    type="duplicateContact"
                    onClick={handleDuplicate}
                    data={contactDetail?.name || ''}
                />

                <CreateOrEditContactModal
                    modalService={createOrEditContactModal}
                    formik={formikOrgCreateContact}
                    isCreate={true}
                />
            </div>
        </FormikProvider>
    )
}

export default ContactList
