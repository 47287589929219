import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { organisationStatusHeaders } from './organisation.static'
import { IOrganisation } from 'repository/interface/organisation.interface'
import { IDropdown } from './organisation-details/organisation-details.interface'

interface IorganisationsSlice {
    data: IOrganisation[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    tabItems: ITabItem[]
    tabItemSelected: ITabItem
    isLoading: boolean
    activeDataList: IOrganisation[]
    nonActiveDataList: IOrganisation[]
    totalActiveData: number
    totalNonActiveData: number
    dataDetail: any
    countries: IDropdown[]
    states: IDropdown[]
    cities: IDropdown[]
    unlocodes: IDropdown[]
    isLoadingCountries: boolean
    isLoadingStates: boolean
    isLoadingCities: boolean
    isLoadingUnlocodes: boolean
}
export const initialState: IorganisationsSlice = {
    activeDataList: [],
    nonActiveDataList: [],
    totalActiveData: 0,
    totalNonActiveData: 0,
    dataDetail: {},

    data: [],
    tabItemSelected: organisationStatusHeaders[0],
    tabItems: organisationStatusHeaders,
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        active: true,
    },
    exportFetchParams: {},
    isLoading: false,

    countries: [],
    states: [],
    cities: [],
    unlocodes: [],
    isLoadingCountries: false,
    isLoadingStates: false,
    isLoadingCities: false,
    isLoadingUnlocodes: false,
}

const organisationsSlice = createSlice({
    name: 'organisations',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<IOrganisation[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            console.log({
                ...state,
                fetchParams: {
                    ...fetchParam,
                    active: data.value as unknown as boolean,
                    recountingData: false,
                },
                tabItemSelected: data,
            })
            return {
                ...state,
                fetchParams: {
                    ...fetchParam,
                    active: data.value as unknown as boolean,
                    recountingData: false,
                },
                tabItemSelected: data,
            }
        },
        setOrganisationDetailData(
            state,
            action: PayloadAction<IResponseData<IOrganisation>>,
        ) {
            const { data } = action.payload

            return {
                ...state,
                dataDetail: data,
            }
        },

        setCountriesData(state, action: PayloadAction<IDropdown[]>) {
            const data = action.payload

            return {
                ...state,
                countries: data,
            }
        },

        setStatesData(state, action: PayloadAction<IDropdown[]>) {
            const data = action.payload

            return {
                ...state,
                states: data,
            }
        },

        setCitiesData(state, action: PayloadAction<IDropdown[]>) {
            const data = action.payload

            return {
                ...state,
                cities: data,
            }
        },

        setUnlocodesData(state, action: PayloadAction<IDropdown[]>) {
            const data = action.payload

            return {
                ...state,
                unlocodes: data,
            }
        },

        setIsLoadingCountries(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoadingCountries: data }
        },

        setIsLoadingStates(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoadingStates: data }
        },

        setIsLoadingCities(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoadingCities: data }
        },

        setIsLoadingUnlocodes(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoadingUnlocodes: data }
        },
    },
})

// these all the variables used for selector
export const organisationsFetchParamSelector = (state: RootState) =>
    state.organisation.fetchParams || {}
export const organisationsExportFetchParamSelector = (state: RootState) =>
    state.organisation.exportFetchParams || {}
export const organisationsDataSelector = (state: RootState) =>
    state.organisation.data || []
export const organisationsMetaSelector = (state: RootState) =>
    state.organisation.meta || {}
export const organisationsTabItemsSelector = (state: RootState) =>
    state.organisation.tabItems
export const organisationsSelectedTabItemSelector = (state: RootState) =>
    state.organisation.tabItemSelected
export const organisationsIsLoading = (state: RootState) =>
    state.organisation.isLoading

// Old Adivia
export const organisationActiveDataSelector = (state: RootState) =>
    state.organisation.activeDataList || []
export const organisationNonActiveDataSelector = (state: RootState) =>
    state.organisation.nonActiveDataList || []
export const organisationDetailDataSelector = (state: RootState) =>
    state.organisation.dataDetail || []
export const organisationTotalActiveDataSelector = (state: RootState) =>
    state.organisation.totalActiveData || 0
export const organisationTotalNonActiveDataSelector = (state: RootState) =>
    state.organisation.totalNonActiveData || 0

export const countriesDataSelector = (state: RootState) =>
    state.organisation.countries
export const statesDataSelector = (state: RootState) =>
    state.organisation.states
export const citiesDataSelector = (state: RootState) =>
    state.organisation.cities
export const unlocodesDataSelector = (state: RootState) =>
    state.organisation.unlocodes
export const countriesIsLoadingSelector = (state: RootState) =>
    state.organisation.isLoadingCountries
export const statesIsLoadingSelector = (state: RootState) =>
    state.organisation.isLoadingStates
export const citiesIsLoadingSelector = (state: RootState) =>
    state.organisation.isLoadingCities
export const unlocodesIsLoadingSelector = (state: RootState) =>
    state.organisation.isLoadingUnlocodes

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
    setOrganisationDetailData,
    setCountriesData,
    setCitiesData,
    setStatesData,
    setUnlocodesData,
    setIsLoadingCountries,
    setIsLoadingCities,
    setIsLoadingStates,
    setIsLoadingUnlocodes,
} = organisationsSlice.actions

export default organisationsSlice.reducer
