import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { ShipmentDataDummy } from './shipments.dummy'

export const ShipmentStatusHeader: ITabItem[] = [
    {
        label: 'All Shipments',
        totalData: ShipmentDataDummy.length,
        key: 'status',
        value: 'allShipments',
        className: 'brand',
    },
    {
        label: 'Confirmed',
        totalData: ShipmentDataDummy.filter(
            (x: any) => x.status === 'confirmed',
        ).length,
        key: 'status',
        value: 'confirmed',
        className: 'green',
    },
    {
        label: 'Booked',
        totalData: ShipmentDataDummy.filter((x: any) => x.status === 'booked')
            .length,
        key: 'status',
        value: 'booked',
        className: 'yellow',
    },
    {
        label: 'Wait Listed',
        totalData: ShipmentDataDummy.filter(
            (x: any) => x.status === 'waitListed',
        ).length,
        key: 'status',
        value: 'waitListed',
        className: 'red',
    },
    {
        label: 'Others',
        totalData: ShipmentDataDummy.filter((x: any) => x.status === 'others')
            .length,
        key: 'status',
        value: 'others',
        className: 'gray',
    },
]

export const ShipmentSummaryHeader: ITableColumn[] = [
    {
        label: 'Shipment ID',
        accessor: 'shipmentID',
    },
    {
        label: 'Goods Description',
        accessor: 'goodsDescription',
    },
    {
        label: 'Shipper Ref',
        accessor: 'shipperReference',
    },
    {
        label: 'Origin Port',
        accessor: 'originPort',
    },
    {
        label: 'Destination Port',
        accessor: 'destinationPort',
    },
    {
        label: 'Packs',
        accessor: 'packs',
    },
    {
        label: 'Weight Measure',
        accessor: 'weightMeasure',
    },
    {
        label: 'Volume Measure',
        accessor: 'volumeMeasure',
    },
    {
        label: 'Master Bill',
        accessor: 'masterBill',
    },
    {
        label: 'Load Port (Arrival Consol)',
        accessor: 'loadPort',
    },
]

export const eDocsTypeOptions = [
    {
        label: 'Agents Instruction',
        value: 'agentsInstruction',
    },
    {
        label: 'Airway Bill/Ocean Bill of Lading',
        value: 'airwayBill',
    },
    {
        label: 'Arrival Notice',
        value: 'arrivalNotice',
    },
    {
        label: 'Arrival Notice and Charge Sheet',
        value: 'arrivalNoticeAndChargeSheet',
    },
    {
        label: 'Beneficiary Certificate',
        value: 'beneficiaryCertificate',
    },
    {
        label: 'Bill Of Entry',
        value: 'billOfEntry',
    },
    {
        label: 'Booking Cartage Advice',
        value: 'bookingCartageAdvice',
    },
    {
        label: 'Booking Confirmation',
        value: 'bookingConfirmation',
    },
    {
        label: 'Cartage Advice With Receipt',
        value: 'cartageAdviceWithReceipt',
    },
    {
        label: 'Certificate of Origin',
        value: 'certificateOfOrigin',
    },
    {
        label: 'Commercial Invoice',
        value: 'commercialInvoice',
    },
    {
        label: 'Container Release',
        value: 'containerRelease',
    },
    {
        label: 'Container Summary Sheet',
        value: 'containerSummarySheet',
    },
    {
        label: 'Cotton Certificate',
        value: 'cottonCertificate',
    },
    {
        label: 'Customs Clearance Documents',
        value: 'customsClearanceDocuments',
    },
    {
        label: 'Customs Status Advice',
        value: 'customsStatusAdvice',
    },
    {
        label: 'Delivery Labels',
        value: 'deliveryLabels',
    },
    {
        label: 'Documents Available Notice',
        value: 'documentsAvailableNotice',
    },
    {
        label: 'Draft Bill of Lading',
        value: 'draftBillOfLading',
    },
    {
        label: 'Entry Print/ Customs Declaration Documents',
        value: 'entryPrint',
    },
    {
        label: 'Export Cartage Advice',
        value: 'exportCartageAdvice',
    },
    {
        label: 'Export Cartage Advice with Receipt',
        value: 'exportCartageAdviceWithReceipt',
    },
    {
        label: 'Export Coload Master Manifest',
        value: 'exportColoadMasterManifest',
    },
    {
        label: 'Exporter Documents',
        value: 'exporterDocuments',
    },
    {
        label: 'Food Control Certificate',
        value: 'foodControlCertificate',
    },
    {
        label: 'Forwarders Cargo Receipt',
        value: 'forwardersCargoReceipt',
    },
    {
        label: 'Fumigation Certificate',
        value: 'fumigationCertificate',
    },
    {
        label: 'Gate Pass',
        value: 'gatePass',
    },
    {
        label: 'Health Clearance Certificate',
        value: 'healthClearanceCertificate',
    },
    {
        label: 'House Bill Of Lading - Signed',
        value: 'houseBillOfLading',
    },
    {
        label: 'House Waybill/ Bill of Lading',
        value: 'houseWaybill',
    },
    {
        label: 'Identification Check',
        value: 'identificationCheck',
    },
    {
        label: 'Image',
        value: 'image',
    },
    {
        label: 'Image - Damage',
        value: 'imageDamage',
    },
    {
        label: 'Import Cartage Advice',
        value: 'importCartageAdvice',
    },
    {
        label: 'Import Cartage Advice with Receipt',
        value: 'importCartageAdviceWithReceipt',
    },
    {
        label: 'Import Coload Master Manifest',
        value: 'importColoadMasterManifest',
    },
    {
        label: 'Insurance Certificate',
        value: 'insuranceCertificate',
    },
    {
        label: 'Internally Created Public Document',
        value: 'internallyCreatedPublicDocument',
    },
    {
        label: 'Letter of Credit',
        value: 'letterOfCredit',
    },
    {
        label: 'Load List',
        value: 'loadList',
    },
    {
        label: "Manufacturer's Declaration",
        value: 'manufacturersDeclaration',
    },
    {
        label: 'Master House',
        value: 'masterHouse',
    },
    {
        label: 'Miscellaneous CFS Document',
        value: 'miscellaneousCfsDocument',
    },
    {
        label: 'Miscellaneous Custom Document',
        value: 'miscellaneousCustomDocument',
    },
    {
        label: 'Miscellaneous Freight Document',
        value: 'miscellaneousFreightDocument',
    },
    {
        label: 'Miscellaneous Order Management Document',
        value: 'miscellaneousOrderManagementDocument',
    },
    {
        label: 'Miscellaneous Sales Manager Document',
        value: 'miscellaneousSalesManagerDocument',
    },
    {
        label: 'Miscellaneous Shipping Manager Document',
        value: 'miscellaneousShippingManagerDocument',
    },
    {
        label: 'Motor Vehicle Certificate',
        value: 'motorVehicleCertificate',
    },
    {
        label: 'Multiple Copies',
        value: 'multipleCopies',
    },
    {
        label: 'NAFTA Certificate',
        value: 'naftaCertificate',
    },
    {
        label: 'Original Bill Of Lading',
        value: 'originalBillOfLading',
    },
    {
        label: 'Outturn Report',
        value: 'outturnReport',
    },
    {
        label: 'Packing Declaration',
        value: 'packingDeclaration',
    },
    {
        label: 'Packing List',
        value: 'packingList',
    },
    {
        label: 'Permit',
        value: 'permit',
    },
    {
        label: 'Power of Attorney',
        value: 'powerOfAttorney',
    },
    {
        label: 'Power of Attorney Customs',
        value: 'powerOfAttorneyCustoms',
    },
    {
        label: 'Power of Attorney Forwarding',
        value: 'powerOfAttorneyForwarding',
    },
    {
        label: 'Profit Share Calculation Worksheet',
        value: 'profitShareCalculationWorksheet',
    },
    {
        label: 'Proof of Delivery',
        value: 'proofOfDelivery',
    },
    {
        label: 'Proof of Pickup',
        value: 'proofOfPickup',
    },
    {
        label: 'Quarantine Application/Coversheet',
        value: 'quarantineApplicationCoversheet',
    },
    {
        label: 'Quarantine Certificate',
        value: 'quarantineCertificate',
    },
    {
        label: 'Quarantine Packing Declaration',
        value: 'quarantinePackingDeclaration',
    },
    {
        label: 'Quarantine Print Preview',
        value: 'quarantinePrintPreview',
    },
    {
        label: 'Quarantine Remote Print',
        value: 'quarantineRemotePrint',
    },
    {
        label: 'Release/ Removal Authority',
        value: 'releaseRemovalAuthority',
    },
    {
        label: 'Scheduled Report',
        value: 'scheduledReport',
    },
    {
        label: 'Shippers Departure Notice',
        value: 'shippersDepartureNotice',
    },
    {
        label: 'Shippers Letter of Instruction',
        value: 'shippersLetterOfInstruction',
    },
    {
        label: 'Shipping Advice',
        value: 'shippingAdvice',
    },
    {
        label: 'Time Slot Confirmation',
        value: 'timeSlotConfirmation',
    },
    {
        label: 'Time Slot Request',
        value: 'timeSlotRequest',
    },
    {
        label: 'Transit Declaration of Commitment',
        value: 'transitDeclarationOfCommitment',
    },
    {
        label: 'Veterinary Certificate',
        value: 'veterinaryCertificate',
    },
    {
        label: 'Warehouse Bill of Lading',
        value: 'warehouseBillOfLading',
    },
    {
        label: 'Warehouse Cartage Advice',
        value: 'warehouseCartageAdvice',
    },
    {
        label: 'Warehouse Pick Order Summary',
        value: 'warehousePickOrderSummary',
    },
    {
        label: 'Withholding Certificate',
        value: 'withholdingCertificate',
    },
    {
        label: 'Others',
        value: 'others',
    },
]
