import { IFetchParams, IResponseData } from 'common/common.interface'
import { errorAxiosToast, fetch } from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { IOrganisation } from './interface/organisation.interface'

export const getOrganisations = async (
    params: IFetchParams,
    showErrorToast?: boolean,
) => {
    try {
        const response = await fetch<
            IResponseData<IOrganisation[]>,
            IFetchParams
        >({
            endpoint: endpoints.organisations,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        if (showErrorToast) {
            errorAxiosToast(error, {
                header: 'Failed Fetch',
                message: 'Failed Fetch Bookings Data',
            })
        }
        return undefined
    }
}
