import { IDropdownItem } from '@components/dropdown/dropdown.interface'

export const languageDropdown: IDropdownItem[] = [
    { label: 'ENGLISH - US', value: 'en' },
    { label: 'JAPAN - JP', value: 'ja' },
    { label: 'INDONESIA - ID', value: 'id' },
    { label: 'CHINA - ZH', value: 'zh' },
    { label: 'GERMANY (Deutsch) - DE', value: 'de' },
    { label: 'SPANISH (Español) - ES', value: 'es' },
    { label: 'FRENCH (Français) - FR', value: 'fr' },
    { label: 'POLAND (Polish) - PL', value: 'pl' },
]
