import Overlay from '@components/overlay/overlay.component'
import BookingsFilter from './booking-filter.component'
import { IUseOverlay } from '@components/overlay/overlay.service'
import { useTranslation } from 'react-i18next'
import { IFetchParams } from 'common/common.interface'

const BookingFilterOverlay = ({
    filterOverlayService,
    onFilterSubmit,
}: {
    filterOverlayService: IUseOverlay
    onFilterSubmit: (data: IFetchParams) => void
}) => {
    const { t } = useTranslation()
    return (
        <Overlay overlayService={filterOverlayService} className="filter flex">
            <div className={`w-full`}>
                <div className="flex font-bold text-size-M justify-between items-center px-5 py-3">
                    {t('action.filters')}
                    <div
                        className="cursor-pointer"
                        onClick={() =>
                            filterOverlayService.closeOverlayHandling()
                        }
                    >
                        <i className="ri-close-fill" />
                    </div>
                </div>
                <div className="border-b border-gray-40"></div>
                <BookingsFilter
                    isGenerateReport={false}
                    onFilterSubmit={async (data) => {
                        filterOverlayService.closeOverlayHandling()
                        onFilterSubmit(data)
                        return
                    }}
                />
            </div>
        </Overlay>
    )
}

export default BookingFilterOverlay
