import Dropdown from '@components/dropdown/dropdown.component'
import { dummyCity, dummyCountry, dummyOriginType } from '../user-access.dummy'

const UserFilter = ({
    isGenerateReport = false,
}: {
    isGenerateReport?: boolean
}) => {
    return (
        <div
            className={`flex flex-col gap-5 filter-shipment p-4 overflow-auto ${isGenerateReport && '!py-2'} `}
        >
            <div className="flex-col w-full">
                <Dropdown
                    options={[
                        {
                            label: 'PER - PERSON/INDIVIDUAL',
                            value: 'PER - PERSON/INDIVIDUAL',
                        },
                    ]}
                    label="CATEGORY"
                    onClick={() => {}}
                    placeholder="Select Category"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={dummyCity.map((city) => {
                        return {
                            label: city,
                            value: city,
                        }
                    })}
                    label="CITY"
                    onClick={() => {}}
                    placeholder="Select City"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={dummyCountry.map((city) => {
                        return {
                            label: city,
                            value: city,
                        }
                    })}
                    label="CUNTRY"
                    onClick={() => {}}
                    placeholder="Select Country"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
            <div className="flex-col w-full">
                <Dropdown
                    options={dummyOriginType.map((city) => {
                        return {
                            label: city,
                            value: city,
                        }
                    })}
                    label="ORIGIN TYPE"
                    onClick={() => {}}
                    placeholder="Select Origin Type"
                    isClearable={true}
                    isMultiSelect={true}
                    isSearchable={true}
                    dropDownIndicator={true}
                />
            </div>
        </div>
    )
}

export default UserFilter
