import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { quoteSummaryPillHeaders } from './form.static'
import { useFormik } from 'formik'
import useQuoteValidation, {
    IFormQuote,
    IFormQuotePayload,
    IFormServiceRate,
} from 'form-validation/quote.validation'
import useDropdown from 'common/dropdown/dropdown.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import useOverlay from '@components/overlay/overlay.service'
import {
    getQuotationDetail,
    submitQuotation,
    updateQuotation,
} from 'repository/quotes.repository'
import moment from 'moment'

const useNewQuote = (isDetailPage: boolean) => {
    const navigate = useNavigate()
    const serviceRatesOverlay = useOverlay()
    const params = useParams()

    // states
    const [selectedTransport, setSelectedTransport] =
        useState<IDropdownItem<ITransportMode> | null>(null)
    const [pillItems, setPillItems] = useState(quoteSummaryPillHeaders[0])
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [isDetailLoading, setIsDetailLoading] = useState(false)
    const dropdownService = useDropdown()

    // formik
    const quoteValidtion = useQuoteValidation()
    const formik = useFormik<IFormQuote>({
        validationSchema: quoteValidtion.formQuoteSchema,
        initialValues: quoteValidtion.initialValue,
        onSubmit: (values) => {
            submitForm(values)
        },
    })
    const formikValues = formik.values
    const {
        transport_mode,
        container_mode,
        origin_location,
        destination_location,
    } = formikValues

    const submitForm = async (value: IFormQuote) => {
        setIsSubmitLoading(true)
        const renewValue: IFormQuote = {
            ...value,
            quote_date: moment(value.quote_date).format('YYYY-MM-DD HH:mm:ss'),
            etd: moment(value.etd).format('YYYY-MM-DD HH:mm:ss'),
        }

        const payload: IFormQuotePayload = {
            quote_date: moment(value.quote_date).format('YYYY-MM-DD HH:mm:ss'),
            etd: moment(value.etd).format('YYYY-MM-DD HH:mm:ss'),
            shipping_qty: renewValue.shipping_rate.qty,
            name: renewValue.name,
            doc_validity_period: renewValue.doc_validity_period,
            customer_name: renewValue.customer_name,
            transport_mode: renewValue.transport_mode,
            container_mode: renewValue.container_mode,
            origin_location: {
                code: renewValue.origin_location.code,
            },
            destination_location: {
                code: renewValue.destination_location.code,
            },
            currency: {
                code: renewValue.currency.code,
            },
            weight: renewValue.weight,
            weight_unit: { code: renewValue.weight_unit },
            volume: renewValue.volume,
            volume_unit: { code: renewValue.volume_unit },
            shipping_rate: {
                code: renewValue.shipping_rate.code,
            },
            notes: renewValue.notes,
            services: renewValue.services.map((data) => {
                return {
                    charge_code: { code: data.charge_code.code },
                    qty: data.qty,
                }
            }),
        }

        try {
            let response: true | undefined
            if (isDetailPage) {
                const code = params.id ?? ''
                response = await updateQuotation(code, payload)
            } else {
                response = await submitQuotation(payload)
            }
            setIsSubmitLoading(false)
            if (response) {
                navigate('/quotes')
            }
        } catch (error) {
            setIsSubmitLoading(false)
            console.log(error)
        }
    }

    const deleteServiceRateById = (index: number) => {
        const serviceRateData = formik.values.services
        if (serviceRateData.length === 1) {
            return
        }
        const updateData = serviceRateData.filter((d, i) => index !== i)
        formik.setValues((state) => ({ ...state, services: updateData }))
    }

    const addNewRow = () => {
        const serviceRateData = formik.values.services
        const newServiceRate: IFormServiceRate = {
            charge_code: { code: '' },
            price: 0,
            unit: '',
            qty: 0,
            total: 0,
            code: '',
        }
        formik.setValues((state) => ({
            ...state,
            services: [...serviceRateData, newServiceRate],
        }))
    }

    const getDetailData = async () => {
        const code = params.id
        setIsDetailLoading(true)
        if (!code) {
            navigate('/quotes')
            setIsDetailLoading(false)
            return
        }
        const response = await getQuotationDetail(code)
        if (!response) {
            navigate('/quotes')
            setIsDetailLoading(false)
            return
        }

        const data = response.data
        const newShippingRates = data.shipping_rate
        dropdownService.getContainerModes(data.transport_mode ?? '')
        const newValue: IFormQuote = {
            container_mode: data.container_mode ?? '',
            currency: data.currency ?? {
                created: '',
                updated: '',
                code: '',
                name: '',
                symbol: '',
                test_flag: false,
                created_by: '',
                updated_by: '',
                entity_status: '',
                short_code: '',
            },
            customer_name: data.customer_name ?? '',
            name: data.name ?? '',
            quote_date: data.quote_date ?? '',
            etd: data.etd ?? '',
            doc_validity_period: data.doc_validity_period ?? '',
            transport_mode: data.transport_mode ?? '',
            origin_location: data.origin_location ?? { code: '', name: '' },
            destination_location: data.destination_location ?? {
                code: '',
                name: '',
            },
            weight: data.weight ?? 0,
            weight_unit: data.weight_unit?.code ?? '',
            volume: data.volume ?? 0,
            volume_unit: data.volume_unit?.code ?? '',
            shipping_rate: {
                code: newShippingRates?.code ?? '',
                price: 0,
                priceString: '',
                currencyShort: '',
                unit: '',
                qty: data.shipping_qty ?? 0,
                total: 0,
                totalString: '',
            },
            serviceTemplateName: 'Custom',
            serviceTemplateCode: '',
            services: !data.services
                ? []
                : data.services.map((d) => {
                      const val: IFormServiceRate = {
                          code: d.code ?? '',
                          charge_code: {
                              code: d.charge_code?.code ?? '',
                          },
                          price: d.charge_code?.price ?? 0,
                          unit: d.charge_code?.unit.label ?? '',
                          qty: d.qty ?? 0,
                          total: 0,
                      }
                      return val
                  }),
            notes: data.notes ?? '',
        }
        formik.setValues(newValue)
        setTimeout(() => {
            setIsDetailLoading(false)
        }, 300)
    }

    useEffect(() => {
        if (isDetailPage) {
            getDetailData()
        }
    }, [])
    useEffect(() => {
        dropdownService.getTransportModes()
        dropdownService.getCurrency()
        dropdownService.getWeightUnit()
        dropdownService.getVolumeUnit()
        dropdownService.getServiceRates()
        dropdownService.getUnlocos()
        dropdownService.getChargeCode()
    }, [])

    useEffect(() => {
        if (
            !transport_mode ||
            !container_mode ||
            !origin_location.code ||
            !destination_location.code
        ) {
            return
        }
        dropdownService.getShippingRates({
            transport: transport_mode,
            container: container_mode,
            origin: origin_location.code,
            destination: destination_location.code,
        })
    }, [transport_mode, container_mode, origin_location, destination_location])

    useEffect(() => {
        if (!selectedTransport) return
        dropdownService.getContainerModes(selectedTransport.value as string)
    }, [selectedTransport])

    return {
        navigate,
        setPillItems,
        deleteServiceRateById,
        addNewRow,
        setSelectedTransport,
        isDetailLoading,
        formik,
        pillItems,
        dropdownService,
        formikValues,
        serviceRatesOverlay,
        isSubmitLoading,
    }
}

export default useNewQuote
