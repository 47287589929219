import { IFetchParams, IResponseData } from 'common/common.interface'
import { fetch } from 'common/common.service'
import { endpoints } from 'common/endpoints/loglines.endpoints'
import { ICurrency } from './interface/currency.interface'
import { ITransportMode } from './interface/transport-mode.interface'
import { IShippingRate } from './interface/shipping-rate.interface'
import { IServiceRate } from './interface/service-rate.interface'
import { IUnit } from './interface/unit.interface'
import { IUnlocodes } from './interface/unlocodes.interface'
import { IChargeCode } from './interface/charge-code.interface'
import {
    IOrganisation,
    IOrganisationCategories,
} from './interface/organisation.interface'
import { IAddress } from './interface/address.interface'
import { IContact } from './interface/contact.interface'
import { IRoles } from './interface/role.interface'
import { IBranch } from './interface/branch.interface'
import { ICities, ICountries, IStates } from './interface/country.interface'
import { IJobCategories } from './interface/user.interface'
import { IBookingStatus } from './interface/booking.interface'

export const fetchContainerTypes = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<any[]>, IFetchParams>({
            endpoint: endpoints.containerTypes,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchCurrency = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<IResponseData<ICurrency[]>, IFetchParams>({
            endpoint: endpoints.currencies,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}
export const fetchBranches = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<IResponseData<IBranch[]>, IFetchParams>({
            endpoint: endpoints.branches,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}
export const fetchRoles = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<IResponseData<IRoles[]>, IFetchParams>({
            endpoint: endpoints.roles,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchTransportMode = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<
            IResponseData<ITransportMode[]>,
            IFetchParams
        >({
            endpoint: endpoints.transportMode,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchShippingRates = async (payload: IFetchParams) => {
    const params: IFetchParams = { sort: 'name', order: 'asc', ...payload }
    try {
        const response = await fetch<
            IResponseData<IShippingRate[]>,
            IFetchParams
        >({
            endpoint: endpoints.shippingRates,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchServiceRates = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<
            IResponseData<IServiceRate[]>,
            IFetchParams
        >({
            endpoint: endpoints.serviceRates,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchWeightUnit = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnit[]>, IFetchParams>({
            endpoint: endpoints.units + '/weights',
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchVolumeUnit = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnit[]>, IFetchParams>({
            endpoint: endpoints.units + '/volumes',
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchlengthUnit = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnit[]>, IFetchParams>({
            endpoint: endpoints.units + '/lengths',
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchChargeCode = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<
            IResponseData<IChargeCode[]>,
            IFetchParams
        >({
            endpoint: endpoints.chargeCodes,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchContainerModes = async (transportCode: string) => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<
            IResponseData<ITransportMode[]>,
            IFetchParams
        >({
            endpoint: `${endpoints.transportMode}/${transportCode}/container_modes`,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchUnlocos = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IUnlocodes[]>, IFetchParams>(
            {
                endpoint: `${endpoints.unlocodes}`,
                params: params,
            },
        )
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}
export const fetchBookingStatuses = async () => {
    try {
        const response = await fetch<
            IResponseData<IBookingStatus[]>,
            IFetchParams
        >({
            endpoint: `${endpoints.bookingStatuses}`,
            params: {},
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchOrganisationCategories = async () => {
    try {
        const response = await fetch<
            IResponseData<IOrganisationCategories[]>,
            IFetchParams
        >({
            endpoint: `${endpoints.organisationCategories}`,
            params: {},
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchOrganisations = async (
    organisationType?:
        | 'warehouses'
        | 'customers'
        | 'agents'
        | 'consignees'
        | 'transports'
        | 'carriers'
        | 'suppliers'
        | 'notifies'
        | 'vips'
        | 'services'
        | 'sales'
        | 'shippers'
        | 'stores'
        | '',
) => {
    try {
        const response = await fetch<
            IResponseData<IOrganisation[]>,
            IFetchParams
        >({
            endpoint: endpoints.organisations + '/' + organisationType,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchJobCategories = async () => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<
            IResponseData<IJobCategories[]>,
            IFetchParams
        >({
            endpoint: `${endpoints.job_categories}`,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchOrganisationsAddress = async ({
    organisationCode,
}: {
    organisationCode: string
}) => {
    try {
        const response = await fetch<IResponseData<IAddress[]>, IFetchParams>({
            endpoint: `${endpoints.organisations}/${organisationCode}/addresses`,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchCities = async (state?: string) => {
    const params: IFetchParams = { sort: 'name', order: 'asc', state }
    try {
        const response = await fetch<IResponseData<ICities[]>, IFetchParams>({
            endpoint: `${endpoints.cities}`,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}
export const fetchStates = async (country: string) => {
    const params: IFetchParams = {
        sort: 'name',
        order: 'asc',
        country,
    }
    try {
        const response = await fetch<IResponseData<IStates[]>, IFetchParams>({
            endpoint: `${endpoints.states}`,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchOrganisationsContact = async ({
    organisationCode,
}: {
    organisationCode: string
}) => {
    const params: IFetchParams = {}
    try {
        const response = await fetch<IResponseData<IContact[]>, IFetchParams>({
            endpoint: `${endpoints.organisations}/${organisationCode}/contacts`,
            params: params,
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchContact = async () => {
    try {
        const response = await fetch<IResponseData<IContact[]>, IFetchParams>({
            endpoint: `${endpoints.contacts}`,
            params: {},
        })
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}

export const fetchCountries = async () => {
    const params: IFetchParams = { sort: 'name', order: 'asc' }
    try {
        const response = await fetch<IResponseData<ICountries[]>, IFetchParams>(
            {
                endpoint: endpoints.countries,
                params: params,
            },
        )
        return response
    } catch (error: unknown) {
        console.error(error)
        return undefined
    }
}
