import Content from 'layout/content.component'
import Header from 'layout/header.component'
import { menuHoverSelector } from 'layout/layout.slice'
import Sidebar from 'layout/sidebar.component'
import { Outlet } from 'react-router'
import { useAppSelector } from 'store'

function App(): React.ReactElement {
    const menuHover = useAppSelector(menuHoverSelector)
    const contentZIndex = menuHover ? 'z-[-1]' : 'sm:z-[0]'
    return (
        <>
            <div className="logistical-layout">
                <div className="flex">
                    <Sidebar />
                    <div
                        className={`flex-grow flex flex-col order-2 overflow-auto ${contentZIndex}`}
                    >
                        <div className="logistical-content order-last">
                            <Content>
                                <Outlet />
                            </Content>
                        </div>
                        <Header />
                    </div>
                </div>
            </div>
        </>
    )
}

export default App
