var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import './spinner.style.css';
var Spinner = function (_a) {
    var label = _a.label, _b = _a.className, className = _b === void 0 ? '' : _b;
    return (_jsxs("div", __assign({ className: "flex flex-col items-center" }, { children: [_jsx("div", { className: 'loader mb-3 ' + className }), !label ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "text-size-M themes-text-gray-v6" }, { children: label })))] })));
};
export default Spinner;
