import axios from 'axios'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { IUserAuth } from 'repository/interface/user-auth.interface'
import { IBuildVersion, IConfig } from './config.interface'
import { useEffect, useRef, useState } from 'react'
import {
    currentThemeSelector,
    setClientVersion,
    setConfig,
    setTheme,
} from './config.slice'
import { resetStore, store } from 'store'
import { ThemeType } from 'common/common.interface'
import { removeSessionAction, userDataSelector } from 'pages/login/login.slice'

export const useConfig = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const user: IUserAuth = useSelector(userDataSelector)
    const currentTheme: ThemeType = useSelector(currentThemeSelector)
    const [sessionExpDate, setSessionExpDate] = useState<Date | null>(null)
    const [triggerEvent, setTriggerEvent] = useState<boolean>(false)

    // ignore session check in this route
    const [ignoredRoutes] = useState(['/login', '/logout'])

    // check session function
    const checkSession = (sess?: Date) => {
        if (!sess) return

        const now = new Date()
        if (sess < now) {
            removeSession()
            setSessionExpDate(null)
        }
    }

    const checkSessionRef = useRef(() => {
        checkSession()
    })

    // Update checkSessionRef.current whenever sessionExpDate changes
    useEffect(() => {
        const cSessionRef = checkSessionRef.current
        if (!sessionExpDate) {
            window.removeEventListener('mousemove', cSessionRef)
            return
        }
        checkSessionRef.current = () => {
            checkSession(sessionExpDate)
        }
        setTimeout(() => {
            setTriggerEvent(!triggerEvent)
        }, 400)
    }, [sessionExpDate])

    useEffect(() => {
        const checkSession = checkSessionRef.current
        window.addEventListener('mousemove', checkSession)
    }, [triggerEvent])

    // axios -------------------------------------------------------------------
    if (user) {
        axios.defaults.paramsSerializer = { indexes: null }
        axios.defaults.headers.common['Content-Type'] = 'application/json'
        axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
        axios.defaults.headers.common.Accept = 'application/json'
        axios.defaults.headers.common.Authorization = `Bearer ${user.token}`
        axios.defaults.timeout = 500000
    }

    // use Effect ---------------------------------------------------------------
    useEffect(() => {
        starting()
    }, [])

    useEffect(() => {
        if (user.token === '') starting()
    }, [user])

    useEffect(() => {
        const theme = currentTheme ?? 'default'
        const body = document.body
        body.removeAttribute('class')
        body.classList.add(theme)
    }, [currentTheme])

    useEffect(() => {
        // handling session
        if (!user?.token) {
            if (!ignoredRoutes.includes(location.pathname)) {
                navigate('/login', {
                    replace: true,
                })

                // reset store
                resetStore()
            }
        } else {
            const token = user.token
            const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))

            //------------
            // if session problem fix, delete this
            // var currentTimestamp = Date.now()
            // var futureTimestamp = currentTimestamp + 20 * 1000
            // var futureDate = new Date(futureTimestamp)
            //------------

            // Replace timestamp_variable with your timestamp
            const timestamp: number = jwtPayload.exp
            // Convert timestamp to milliseconds
            var sessionDate = new Date(timestamp * 1000)
            // Get current time
            var now = new Date()
            // Compare timestamps
            if (sessionDate < now) {
                removeSession()
                setSessionExpDate(null)
            }

            // trigger event listener
            setSessionExpDate(sessionDate)
        }
    }, [user])

    // function ---------------------------------------------------------------
    const starting = async () => {
        try {
            // get from selector
            const timestamp = Date.now()
            axios
                .get('/config.json?p=' + timestamp, {
                    // query URL without using browser cache
                    headers: {
                        'Cache-Control': 'no-cache',
                        Pragma: 'no-cache',
                        Expires: '0',
                    },
                })
                .then((data) => {
                    const conf = data.data
                    const cf: IConfig = {
                        ...conf,
                    }
                    dispatch(setConfig(cf))

                    // Set Theme Color used
                    if (!currentTheme) {
                        // Default Settings
                        let themeUsed: ThemeType = 'bright-sky-blue-themes'
                        dispatch(setTheme(themeUsed))
                    }
                })

            // get build version
            axios
                .get<IBuildVersion>('/build-version.json?p=' + timestamp)
                .then((data) => {
                    const buildVersion = data.data
                    const clientVersion = buildVersion.clientVersion
                    const lastBuild = buildVersion.lastBuild
                    console.info(
                        'Loglines' +
                            '\nClient Version: ' +
                            clientVersion +
                            '\nLast Build: ' +
                            lastBuild,
                    )
                    const nbv: IBuildVersion = {
                        ...buildVersion,
                    }
                    dispatch(setClientVersion(nbv))
                })
        } catch (e) {
            console.log(e)
        }
    }
}

export function getConfig(): IConfig {
    const state = store.getState()
    return state.config.config
}

export const removeSession = () => {
    store.dispatch(removeSessionAction())
}
