const FreightType = ({
    isSelected,
    icon,
    title,
    subtitle,
    onClick,
}: {
    icon: string
    title: string
    isSelected: boolean
    subtitle: string
    onClick: () => void
}) => {
    const titleTextClassName = isSelected
        ? 'themes-text-brand-v5'
        : 'themes-text-gray-v6'
    const subTitleTextClassName = isSelected
        ? 'themes-text-brand-v4'
        : 'themes-text-gray-v5'
    const bgClassName = isSelected ? 'themes-bg-brand-v1' : ''
    const iconBgClassName = isSelected
        ? 'themes-bg-brand-v2'
        : 'themes-bg-gray-v2'
    const borderClassName = isSelected
        ? 'themes-border-brand-v3'
        : 'themes-border-gray-v2'
    return (
        <div
            className={`w-full rounded border cursor-pointer
            ${bgClassName} ${borderClassName} items-center flex flex-col justify-center p-2`}
            onClick={onClick}
        >
            <div
                className={`flex items-center justify-center rounded-full h-[50px] w-[50px] ${iconBgClassName} rounded`}
            >
                <i className={`text-size-L ${icon} ${titleTextClassName}`}></i>
            </div>
            <div className={`text-size-M py-2 ${titleTextClassName}`}>
                {title}
            </div>
            <div
                className={`text-size-XS pb-2 text-center ${subTitleTextClassName}`}
            >
                {subtitle}
            </div>
        </div>
    )
}

export default FreightType
