import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { OptionProps, components } from 'react-select'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'

const OptionShippingRates = (
    props: OptionProps<IDropdownItem<IShippingRate>>,
) => {
    const { data, isSelected } = props

    const price = data.additionalData?.price ?? 0
    const priceFormatted = price.toLocaleString('en-US', {
        minimumFractionDigits: price % 1 !== 0 ? 2 : 0,
        maximumFractionDigits: 2,
    })

    const currency = data.additionalData?.currency?.short_code ?? ''
    const perUnit = data.additionalData?.unit?.code ?? ''
    const carrierName = data.additionalData?.carrier?.name ?? ''

    // Styling
    const carrierColors = isSelected
        ? 'themes-text-gray-v5'
        : 'themes-text-gray-v4'
    const carrierColors2 = isSelected
        ? 'themes-text-gray-v5'
        : 'themes-text-gray-v5'
    const rateColors = isSelected
        ? 'themes-text-brand-v5 font-bold'
        : 'themes-text-gray-v5'
    const rateColors2 = isSelected
        ? 'themes-text-gray-v4'
        : 'themes-text-gray-v4'

    return (
        <components.Option {...props}>
            <div className=" ">
                <div className="p-1 flex gap-1 justify-between items-center">
                    <div className="w-full">
                        <div
                            className={`${rateColors} themes-text-gray-v5 text-size-XS`}
                        >
                            Carrier Rate
                        </div>
                        <div className="flex flex-wrap justify-between items-center">
                            <div className={`${rateColors} text-size-M`}>
                                {currency} {priceFormatted}
                                <div
                                    className={`${rateColors2} themes-text-gray-v4 text-size-XS inline`}
                                >
                                    /{perUnit}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className={`${carrierColors} text-size-XS`}>
                            Carrier Name
                        </div>
                        <div className={`${carrierColors2} text-size-S`}>
                            {carrierName}
                        </div>
                    </div>
                </div>
            </div>
        </components.Option>
    )
}

export default OptionShippingRates
