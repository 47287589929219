import TabMaster from '@components/tab-master/tab-master.component'
import Table from '@components/table/table.component'
import { IQuotation } from 'repository/interface/quotations.interface'
import Button from '@components/button/button.component'
import useOrganisations from './organisation.service'
import { organisationSummaryHeaders } from './organisation.static'
import { IOrganisation } from 'repository/interface/organisation.interface'
import OrganisationFilterOverlay from './components/organisation-filter-overlay.component'
import OrganisationExportModal from './components/organisation-export.component'

const Organisations = () => {
    const {
        tabItems,
        organisationsMeta,
        organisationsData,
        selectedTabItem,
        loading,
        fetchParam,
        filterOverlay,
        exportModalService,
        exportLoading,
        loadData,
        setTabFilter,
        handleSearch,
        navigate,
        handlePagination,
        setExportLoading,
        exportLoadData,
    } = useOrganisations()

    const rowOptionComponent = (
        rowData: IQuotation,
        closeHandling: () => void,
    ) => (
        <div className="w-full">
            <Button
                onClick={() => {
                    closeHandling()
                }}
                className="!w-full border-none text-left"
                icon="ri-download-2-line"
                label="Download Pdf"
            />
            <Button
                onClick={() => {
                    closeHandling()
                }}
                className="!w-full border-none text-left"
                variant={'red-inverse'}
                label="Remove"
                icon="ri-delete-bin-line"
            />
        </div>
    )

    return (
        <div className="container-global content-full-height flex">
            <div className="flex flex-grow flex-col w-[calc(100vw-20rem)] sm:w-[calc(100vw-4rem)]">
                <TabMaster
                    items={tabItems || []}
                    tabFilter={selectedTabItem}
                    onChange={(item) => setTabFilter(item)}
                    //  search ----------------------------------------------------------------
                    useSearch={true}
                    placeHolderSearch={'Search Organisation '}
                    containerSearchClassName={'flex-1'}
                    initialSearch={fetchParam.search}
                    onSearchSubmit={(values) => handleSearch(values)}
                    iconButton1={{
                        onClick: () => filterOverlay.toggleOverlayHandling(),
                        icon: 'ri-filter-2-line',
                        filterOverlayComponent: (
                            <OrganisationFilterOverlay
                                filterOverlayService={filterOverlay}
                                onFilterSubmit={(data) => {
                                    loadData({ ...fetchParam, ...data })
                                    return
                                }}
                            />
                        ),
                    }}
                    actionButton1={{
                        onClick: () => {
                            navigate('/create-organisation')
                        },
                        label: 'NEW ORGANISATION',
                        className: '!text-size-S',
                    }}
                />

                <Table<IOrganisation>
                    headers={organisationSummaryHeaders}
                    data={organisationsData}
                    loading={loading}
                    nextHandling={(page) => {
                        handlePagination(page)
                    }}
                    previousHandling={(page) => {
                        handlePagination(page)
                    }}
                    meta2={organisationsMeta}
                    moduleTitle={'Organisations'}
                    onRowClick={function (data): void {
                        navigate('/organisation-detail/' + data.code)
                    }}
                    rowOptionComponent={rowOptionComponent}
                    enableExport={true}
                    exportModalService={exportModalService}
                />
            </div>

            <OrganisationExportModal
                modalService={exportModalService}
                onSubmit={async (data) => {
                    setExportLoading(true)
                    await exportLoadData(data)
                    setExportLoading(false)
                    exportModalService.closeModalHandling()
                }}
                isLoading={exportLoading}
            />
        </div>
    )
}

export default Organisations
