import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'
import FormTextArea from '@components/form-textarea/form-textarea.component'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { FormikContextType, FormikProvider } from 'formik'
import { useEffect } from 'react'
import AddressListForm from './address/address-list-form-component'
import NotesListForm from './notes/notes-form.component'

const CreateOrEditNoteModal = ({
    modalService,
    formik,
    isCreate,
}: {
    modalService: IUseModal
    formik: FormikContextType<any>
    isCreate: boolean
}) => {
    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <FormikProvider value={formik}>
                <div>
                    <div className="flex justify-between px-3">
                        <div className="flex items-center font-bold text-size-L">
                            {isCreate ? 'CREATE NEW ADDRESS' : 'EDIT ADDRESS'}
                        </div>
                        <Button
                            onClick={() => modalService.closeModalHandling()}
                            icon="ri-close-fill"
                            className="border-none w-btnIconWidth"
                        />
                    </div>
                    <div className="border-b"></div>
                    <NotesListForm />
                    <div className="border-b"></div>
                    <div className="px-3 mt-3">
                        <Button
                            onClick={() => {
                                modalService.closeModalHandling()
                                formik.submitForm()
                            }}
                            label={
                                isCreate ? 'CREATE ADDRESS' : 'UPDATE ADDRESS'
                            }
                            variant="brand"
                            className="w-full"
                        />
                    </div>
                </div>
            </FormikProvider>
        </Modal>
    )
}

export default CreateOrEditNoteModal
