import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { IAccounts } from '../../organisation-details.interface'
import {
    createOrganisationAccount,
    deleteOrganisationAccounts,
    deleteOrganisationNotes,
    getOrganisationAccountData,
    updateOrganisationAccount,
} from 'pages/organisation/organisation.repository'

export const useAccountListService = () => {
    const { id = 'not found' } = useParams()

    const [accountListData, setAccountListData] = useState<IAccounts[]>([])
    const [accountDetail, setAccountDetail] = useState<IAccounts>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        loadAccountList()

        return () => {
            setAccountListData([])
        }
    }, [])

    const formikOrgAccountDetail = useFormik<IAccounts>({
        initialValues: {
            name: '',
            account_number: '',
            routing_number: '',
            remark: '',
        },
        onSubmit: async (values) => {
            if (id) {
                const res = await updateOrganisationAccount(id, values)
                if (res) {
                    loadAccountList()
                }
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateAccount = useFormik<IAccounts>({
        initialValues: {
            name: '',
            account_number: '',
            routing_number: '',
            remark: '',
        },
        onSubmit: async (values) => {
            if (id) {
                const res = await createOrganisationAccount(id, values)
                if (res) {
                    loadAccountList()
                }
            }
        },
        enableReinitialize: true,
    })

    const handleAccountDetail = (data: IAccounts) => {
        if (data) {
            setAccountDetail(data)
            formikOrgAccountDetail.setValues(data)
        }
    }

    const handleDuplicate = async () => {
        if (id && accountDetail) {
            const res = await createOrganisationAccount(id, accountDetail)
            if (res) {
                loadAccountList()
            }
        }
    }

    const loadAccountList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationAccountData(id)
            setAccountListData(response.data as IAccounts[])

            if (response?.data?.length > 0) {
                formikOrgAccountDetail.setValues(response.data?.[0])
                setAccountDetail(response.data?.[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const deleteAccount = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationAccounts(id, dataId)
            const filterData = accountListData.filter(
                (data) => data.code !== dataId,
            )
            setAccountListData(filterData)

            if (filterData?.length > 0) {
                formikOrgAccountDetail.setValues(filterData[0])
                setAccountDetail(filterData[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    return {
        loadAccountList,
        handleAccountDetail,
        formikOrgCreateAccount,
        formikOrgAccountDetail,
        loading,
        accountListData,
        accountDetail,
        deleteAccount,
        handleDuplicate,
    }
}
