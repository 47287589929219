import { ReactComponent as LogoLogistical } from '@assets/img/icons/loglines_logo.svg'

export default function WIP() {
    return (
        <div className="container-global">
            <div className="p-10 text-center"> Work In Progress</div>
        </div>
    )
}

export function DashboardInProgress() {
    return (
        <div className="container-global content-full-height flex items-center justify-center">
            <LogoLogistical className={`themes-icon-brand`} />
        </div>
    )
}
