import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { ITabItem } from '@components/tab/tab.interface'
import { ITableColumn } from '@components/table/table.interface'
import { Toast } from '@components/toast/toast.component'
import { IOrganisation } from 'repository/interface/organisation.interface'

export const organisationEndpoints = {
    organisations: 'organisations',
    categories: 'organisation_categories',
    languages: 'languages',
    tax_types: 'tax_types',
    countries: 'countries',
    states: 'states',
    cities: 'cities',
    unlocodes: 'unlocodes',
    document_types: 'document_types',
}
export const organisationStatusHeaders: ITabItem[] = [
    {
        label: 'Active',
        totalData: 0,
        key: 'status',
        value: 'true',
        className: 'brand',
    },
    {
        label: 'Inactive',
        totalData: 0,
        key: 'status',
        value: 'false',
        className: 'red',
    },
]

export const organisationSummaryHeaders: ITableColumn<IOrganisation>[] = [
    {
        label: 'Code',
        accessor: 'code',
    },
    {
        label: 'Full Name',
        accessor: 'name',
    },
    {
        label: 'Category',
        accessor: 'category',
    },
    {
        label: 'City',
        accessor: 'addresses',
        customBuild: (data: any, x, y, z, rowData) => {
            if (data?.[0]) {
                const name = data[0]?.city?.name
                return <>{name}</>
            } else return <>-</>
        },
    },
    {
        label: 'Country',
        accessor: 'country',
        customBuild: (data: any, x, y, z, rowData) => {
            if (data?.[0]) {
                const name = data[0]?.country?.name
                return <>{name}</>
            } else return <>-</>
        },
    },
    {
        label: 'Email',
        accessor: 'contacts',
        customBuild: (data: any, x, y, z, rowData) => {
            if (data?.[0]) {
                const name = data[0]?.email
                return <>{name}</>
            } else return <>-</>
        },
    },
    {
        label: 'Number',
        accessor: 'number',
        customBuild: (data: any, x, y, z, rowData) => {
            if (data?.[0]) {
                const name = data[0]?.phone_no
                return <>{name}</>
            } else return <>-</>
        },
    },
    {
        label: 'Org Type',
        accessor: 'category',
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
    },
]

export const handleFeatureInDevelopment = () =>
    Toast({
        header: 'Feature in Development',
        message:
            'This feature is currently under development and will be available soon!',
        type: 'info',
    })

export const organisationType: IDropdownItem[] = [
    { label: 'Shipper', value: 'shipper' },
    { label: 'Consignee', value: 'consignee' },
    { label: 'Agent', value: 'agent' },
    { label: 'Customer', value: 'customer' },
    { label: 'Transport', value: 'transport' },
    { label: 'Warehouse', value: 'warehouse' },
    { label: 'Carrier', value: 'carrier' },
    { label: 'Supplier', value: 'supplier' },
    { label: 'Notify', value: 'notify' },
    { label: 'VIP', value: 'vip' },
    { label: 'Services', value: 'services' },
    { label: 'Sales', value: 'sales' },
    { label: 'Store', value: 'store' },
]
