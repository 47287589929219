/* eslint-disable no-unused-vars */
import Input from '@components/input/input.component'
import Checkbox from '@components/checkbox/checkbox.component'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { FormikContextType, FormikProvider } from 'formik'
import FormInput from '@components/form-input/form-input.component'
import { IUseModal } from '@components/modal/modal.service'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import Button from '@components/button/button.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { contactTypeHeaders } from '../../organisation-details.static'
import { IOrganisation } from 'pages/organisation/organisation.interface'
import RemoveModalAddress from '../remove-modal.component'
import useDetailsService from './details.service'
import useOrganisationDetail from '../../organisation-details.service'
import OrganisationDetailsForm from './details-form.component'

const Details = () => {
    const { isEdit, formikOrgDetails } = useDetailsService()
    const { removeModalService: modalService } = useOrganisationDetail()

    return (
        <FormikProvider value={formikOrgDetails}>
            <OrganisationDetailsForm
                isEdit={isEdit}
                formik={formikOrgDetails}
            />
            {/* <RemoveModalAddress
                modalService={modalService}
                type={
                    entityStatus === 'ACTJVE' ? 'nonActiveOrg' : 'reactivateOrg'
                }
                data={data.fullName}
                onClick={() => {
                    const toggle =
                        data.status === 'active' ? 'nonActive' : 'active'
                    setData({ ...data, status: toggle })
                }}
            /> */}
        </FormikProvider>
    )
}

export default Details
