var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useState } from "react";
var Toggle = function (props) {
    var _a = useState(true), isEnable = _a[0], setIsEnable = _a[1];
    var classActive = 'themes-bg-brand-v2 themes-text-brand-v4';
    var classNotActive = 'themes-text-gray-v4';
    var enableClassname = isEnable ? classActive : classNotActive;
    var disableClassname = isEnable ? classNotActive : classActive;
    return (_jsxs("div", __assign({ className: "".concat((props === null || props === void 0 ? void 0 : props.classnNames) || '', " flex p-1 border items-center rounded justify-evenly text-size-S") }, { children: [_jsx("div", __assign({ className: "flex justify-center w-1/2 py-1 rounded cursor-pointer ".concat(enableClassname), onClick: function () { return setIsEnable(true); } }, { children: props.lableEnable })), _jsx("div", __assign({ className: "flex justify-center w-1/2 py-1 rounded cursor-pointer ".concat(disableClassname), onClick: function () { return setIsEnable(false); } }, { children: props.lableDisable }))] })));
};
export default Toggle;
