import React from 'react'
import FormInput from '@components/form-input/form-input.component'
import FormTextarea from '@components/form-textarea/form-textarea.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormToggleSwitch from '@components/form-toggle-switch/form-toggle-switch.component'
import useCreateOrganisation from 'pages/organisation/create-organisation/create-organisation.service'
import useAddressListService from './address-list.service'

const AddressListForm = () => {
    const { countries, states, cities, unlocodes } = useAddressListService()

    return (
        <div className="overflow-auto">
            <div className="flex flex-col py-3 gap-3">
                <FormInput
                    label="Address Name"
                    name="name"
                    parentDivClassName="!mb-0"
                    placeholder="Enter Address Name"
                />
                <FormTextarea
                    label="Address 1"
                    name="street"
                    placeholder="Enter Address"
                />
                <FormTextarea
                    label="Address 2 (Optional)"
                    name="street_2"
                    placeholder="Enter Address"
                />
                <div className="w-full flex gap-3">
                    <FormDropdown
                        name="country.code"
                        label="Country/Region"
                        placeholder="Select Country"
                        options={countries}
                        parentDivClassName="w-1/2 !mb-0"
                    />
                    <FormDropdown
                        name="state.code"
                        label="State"
                        placeholder="Select State"
                        parentDivClassName="w-1/2 !mb-0"
                        disabled={states?.length === 0}
                        options={states}
                    />
                </div>
                <div className="w-full flex gap-3">
                    <FormDropdown
                        name="city.code"
                        label="City"
                        placeholder="Select City"
                        disabled={cities?.length === 0}
                        options={cities}
                        parentDivClassName="w-1/2 !mb-0"
                    />
                    <FormInput
                        name="post_code"
                        label="Postcode"
                        placeholder="Select Postcode"
                        parentDivClassName="w-1/2 !mb-0"
                    />
                </div>
                <FormDropdown
                    name={'unlocode'}
                    label="UNLOCO"
                    placeholder="Select UNLOCO"
                    options={unlocodes}
                />
                <FormInput
                    label="Related City/Port (Optional)"
                    parentDivClassName="!mb-0"
                    name="unlocode"
                    disabled={true}
                />
                <FormToggleSwitch label={'Active Address'} name="is_active" />
            </div>
        </div>
    )
}

export default AddressListForm
