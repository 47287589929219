var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getStatusClass } from './status-card.service';
var StatusCard = function (_a) {
    var status = _a.status;
    var statusClass = getStatusClass(status);
    return (_jsx("div", __assign({ className: "inline-block ".concat(statusClass) }, { children: _jsx("span", __assign({ className: "font-normal pb-2 pt-3 px-[10px] rounded text-size-S" }, { children: status.toUpperCase() })) })));
};
export default StatusCard;
