import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import Button from '@components/button/button.component'
import '../user-access-form.style.css'
import { FormikContextType, FormikProvider } from 'formik'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import { useSelectModule } from './select-module-modal.service'
import {
    IRoleModule,
    IRoleSubModule,
    IRoleSubModuleAction,
} from 'repository/interface/role.interface'
import ToggleSwitch from '@components/toggle-switch/toggle-switch.component'
import { capitalizeFirstLetter } from '@services/common.service'

const SelectModuleModal = ({
    modalService,
    formikModule,
    submitHandling,
}: {
    modalService: IUseModal
    formikModule: FormikContextType<any>
    // eslint-disable-next-line no-unused-vars
    submitHandling: (data: IRoleModule[]) => void
}) => {
    const {
        roleModuleData,
        selectedModule,
        selectedSubModule,
        selectModuleHandling,
        selectSubModuleHandling,
        toggleModuleEnable,
        toggleAction,
    } = useSelectModule()

    const roleModuleComponent = roleModuleData.map((module) => {
        const bgClass =
            module.key === selectedModule?.key
                ? 'themes-bg-brand-v1 themes-text-brand-v5'
                : ''
        return (
            <div
                className={`
                flex items-center justify-between w-full px-2 border-b themes-bg-hover-brand-v1 cursor-pointer
                ${bgClass}
            `}
            >
                <div
                    className="flex-grow flex items-center h-[40px] "
                    onClick={() => {
                        selectModuleHandling(module)
                    }}
                >
                    {module.label}
                </div>
                <div>
                    <ToggleSwitch
                        initialOn={module.enable}
                        onClick={() => {
                            toggleModuleEnable(module)
                        }}
                    />
                </div>
            </div>
        )
    })

    const secondDivComponent = () => {
        // check if there's sub module
        // check if there's any action
        const isEnable = selectedModule?.enable
        if (isEnable && selectedModule?.subModule !== undefined) {
            return subModuleComponent(selectedModule.subModule)
        } else if (isEnable && selectedModule?.action !== undefined) {
            return actionComponent(selectedModule.action, selectedModule.key)
        } else {
            return noItemComponent
        }
    }
    const thirdDivComponent = () => {
        if (selectedSubModule?.action !== undefined) {
            return actionComponent(
                selectedSubModule.action,
                selectedModule?.key ?? '',
                selectedSubModule.key,
            )
        } else {
            return noItemComponent
        }
    }

    const subModuleComponent = (subModule: IRoleSubModule[]) => {
        return subModule.map((module) => {
            const bgClass =
                module.key === selectedSubModule?.key
                    ? 'themes-bg-brand-v1 themes-text-brand-v5'
                    : ''
            return (
                <div
                    className={`
                w-full px-2 border-b themes-bg-hover-brand-v1 cursor-pointer
                ${bgClass}
            `}
                    onClick={() => {
                        selectSubModuleHandling(module)
                    }}
                >
                    <div className="flex items-center h-[40px]">
                        {module.label}
                    </div>
                </div>
            )
        })
    }

    const actionComponent = (
        action: IRoleSubModuleAction,
        moduleKey: string,
        subModuleKey?: string,
    ) => {
        const data = Object.keys(action)
            .filter((d) => {
                if (d === undefined) return false
                else return true
            })
            .map((d) => {
                const actTmp = action as any
                return {
                    key: d as 'create' | 'view' | 'remove' | 'edit',
                    label: capitalizeFirstLetter(d.toString()),
                    enable: actTmp[d] as boolean,
                }
            })

        return data.map((act) => {
            return (
                <div
                    className={`w-full flex items-center justify-between w-full px-2  border-b themes-bg-hover-brand-v1 cursor-pointer`}
                >
                    <div className="h-[40px] flex items-center ">
                        {act.label}
                    </div>
                    <div>
                        <ToggleSwitch
                            initialOn={act.enable}
                            onClick={() => {
                                if (subModuleKey) {
                                    toggleAction(
                                        act.key,
                                        moduleKey,
                                        subModuleKey,
                                    )
                                } else {
                                    toggleAction(act.key, moduleKey)
                                }
                            }}
                        />
                    </div>
                </div>
            )
        })
    }

    const noItemComponent = (
        <div className="h-full w-full flex justify-center items-center themes-text-gray-v4">
            No Item
        </div>
    )

    return (
        <Modal
            isModalOpen={modalService.isModalOpen}
            className="lg:!w-[40%] xl:!w-[30%]  md:!w-[50%] sm:!w-[90%]  px-0 !z-1000"
        >
            <div className="flex flex-col h-full">
                <div className="px-4 pb-4 flex  w-full ">
                    {/* Header */}
                    <div className="flex-1 flex-col flex leading-tight">
                        <span className="text-size-M font-bold ">
                            USER ROLES
                        </span>
                    </div>
                    <div className="flex-end ">
                        <i
                            className="ri-close-line cursor-pointer"
                            onClick={() => {
                                modalService.closeModalHandling()
                            }}
                        />
                    </div>
                </div>

                <div className="flex-col p-4 themes-bg-white border-t">
                    <FormikProvider value={formikModule}>
                        <FormDropdown
                            dropDownIndicator={true}
                            name="role"
                            label="Role"
                            options={[
                                {
                                    label: 'Admin',
                                    value: 'admin',
                                },
                                {
                                    label: 'Regular User',
                                    value: 'Regular User',
                                },
                            ]}
                        />
                    </FormikProvider>

                    {/* Body */}
                    <span className="text-size-M font-bold ">
                        Module Adjustment
                    </span>
                    <div className="flex border rounded">
                        <div className="w-[40%] h-[250px] border-r flex-col overflow-auto">
                            {roleModuleComponent}
                        </div>
                        <div className="w-[30%] h-[250px] border-r overflow-auto">
                            {secondDivComponent()}
                        </div>
                        <div className="w-[30%] h-[250px] overflow-auto">
                            {thirdDivComponent()}
                        </div>
                    </div>
                </div>

                <div className="flex flex-2/3 themes-bg-white pt-4  px-4 border-t w-full">
                    {/* Footer */}
                    <Button
                        className="ml-auto"
                        label="Update Role"
                        onClick={() => {
                            submitHandling(roleModuleData)
                            modalService.closeModalHandling()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default SelectModuleModal
