import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchBranches,
    fetchChargeCode,
    fetchCities,
    fetchContainerModes,
    fetchContainerTypes,
    fetchCountries,
    fetchCurrency,
    fetchOrganisations,
    fetchJobCategories,
    fetchRoles,
    fetchServiceRates,
    fetchShippingRates,
    fetchStates,
    fetchTransportMode,
    fetchUnlocos,
    fetchVolumeUnit,
    fetchWeightUnit,
    fetchBookingStatuses,
    fetchOrganisationCategories,
} from 'repository/dropdown.repository'
import { ICurrency } from 'repository/interface/currency.interface'
import {
    dropdownAllContainerModeSelector,
    dropdownBranchesSelector,
    dropdownChargeCodesSelector,
    dropdownCitiesSelector,
    dropdownContainerModeSelector,
    dropdownContainerTypeSelector,
    dropdownCountriesSelector,
    dropdownCurrencySelector,
    dropdownJobCategorySelector,
    dropdownRolesSelector,
    dropdownServiceLengthSelector,
    dropdownServiceRatesSelector,
    dropdownServiceVolumeSelector,
    dropdownServiceWeightSelector,
    dropdownShippingRatesSelector,
    dropdownStatesSelector,
    dropdownTransportModeSelector,
    dropdownUnlocodesSelector,
    dropdownOrganisationsSelector,
    setAllContainerModes,
    setBranches,
    setChargeCodes,
    setCities,
    setContainerModes,
    setContainerType,
    setCountries,
    setCurrency,
    setOrganisations,
    setJobCategory,
    setRoles,
    setServiceRates,
    setShippingRates,
    setStates,
    setTransportMode,
    setUnlocodes,
    setVolumeUnits,
    setWeightUnits,
    setShipperOrganisations,
    dropdownShipperOrganisationsSelector,
    dropdownConsigneeOrganisationsSelector,
    setConsigneeOrganisations,
    setAgentOrganisations,
    dropdownBookingStatusesSelector,
    setBookingStatuses,
    dropdownOrganisationCategoriesSelector,
    setOrganisationCategories,
} from './dropdown.slice'
import { IServiceRate } from 'repository/interface/service-rate.interface'
import { IShippingRate } from 'repository/interface/shipping-rate.interface'
import { ITransportMode } from 'repository/interface/transport-mode.interface'
import { IUnit } from 'repository/interface/unit.interface'
import { IUnlocodes } from 'repository/interface/unlocodes.interface'
import { IFetchParams } from 'common/common.interface'
import { IChargeCode } from 'repository/interface/charge-code.interface'
import {
    IOrganisation,
    IOrganisationCategories,
} from 'repository/interface/organisation.interface'
import { IBranch } from 'repository/interface/branch.interface'
import {
    ICities,
    ICountries,
    IStates,
} from 'repository/interface/country.interface'
import { IBookingStatus } from 'repository/interface/booking.interface'

const useDropdown = () => {
    // Selector & dispatch
    const dispatch = useDispatch()
    const currencyData = useSelector(dropdownCurrencySelector)
    const branchesData = useSelector(dropdownBranchesSelector)
    const rolesData = useSelector(dropdownRolesSelector)
    const containerTypeData = useSelector(dropdownContainerTypeSelector)
    const transportModesData = useSelector(dropdownTransportModeSelector)
    const shippingRatesData = useSelector(dropdownShippingRatesSelector)
    const serviceRatesData = useSelector(dropdownServiceRatesSelector)
    const weightUnitsData = useSelector(dropdownServiceWeightSelector)
    const volumeUnitsData = useSelector(dropdownServiceVolumeSelector)
    const lengthUnitsData = useSelector(dropdownServiceLengthSelector)
    const containerModeData = useSelector(dropdownContainerModeSelector)
    const allContainerModeData = useSelector(dropdownAllContainerModeSelector)
    const unlocodesData = useSelector(dropdownUnlocodesSelector)
    const chargeCodesData = useSelector(dropdownChargeCodesSelector)
    const organisationData = useSelector(dropdownOrganisationsSelector)
    const organisationCategoriesData = useSelector(
        dropdownOrganisationCategoriesSelector,
    )
    const shipperOrganisationData = useSelector(
        dropdownShipperOrganisationsSelector,
    )
    const consigneeOrganisationData = useSelector(
        dropdownConsigneeOrganisationsSelector,
    )
    const agentOrganisationData = useSelector(
        dropdownConsigneeOrganisationsSelector,
    )
    const countriesData = useSelector(dropdownCountriesSelector)
    const statesData = useSelector(dropdownStatesSelector)
    const citiesData = useSelector(dropdownCitiesSelector)
    const jobCategoriesData = useSelector(dropdownJobCategorySelector)
    const bookingStatusesData = useSelector(dropdownBookingStatusesSelector)

    const getCurrency = async () => {
        if (currencyData.length > 0) return
        const response = await fetchCurrency()
        if (!response) return
        const dropdown: IDropdownItem<ICurrency>[] = response.data.map((d) => {
            return {
                label: d.short_code + '\t|\t' + d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setCurrency(dropdown))
    }
    const getBranches = async () => {
        if (branchesData.length > 0) return
        const response = await fetchBranches()
        if (!response) return
        const dropdown: IDropdownItem<IBranch>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setBranches(dropdown))
    }
    const getCountries = async () => {
        if (countriesData.length > 0) return
        const response = await fetchCountries()
        if (!response) return
        const dropdown: IDropdownItem<ICountries>[] = response.data.map(
            (d: any) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setCountries(dropdown))
    }
    const getStates = async (country: string) => {
        const response = await fetchStates(country)
        if (!response) return
        const dropdown: IDropdownItem<IStates>[] = response.data.map(
            (d: any) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setStates(dropdown))
    }
    const getCities = async (state?: string) => {
        const response = await fetchCities(state)
        if (!response) return
        const dropdown: IDropdownItem<ICities>[] = response.data.map(
            (d: any) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setCities(dropdown))
    }
    const getRoles = async () => {
        if (rolesData.length > 0) return
        const response = await fetchRoles()
        if (!response) return
        const dropdown: IDropdownItem<any>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setRoles(dropdown))
    }
    const getContainerType = async () => {
        if (containerTypeData.length > 0) return
        const response = await fetchContainerTypes()
        if (!response) return
        const dropdown: IDropdownItem<any>[] = response.data.map((d) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setContainerType(dropdown))
    }
    const getTransportModes = async () => {
        if (transportModesData.length > 0) return
        const response = await fetchTransportMode()
        if (!response) return
        const dropdown: IDropdownItem<ITransportMode>[] = response.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setTransportMode(dropdown))
    }
    const getShippingRates = async (payload: IFetchParams) => {
        const response = await fetchShippingRates(payload)
        if (!response) return
        const dropdown: IDropdownItem<IShippingRate>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        console.log(dropdown)
        dispatch(setShippingRates(dropdown))
    }

    const getServiceRates = async () => {
        const response = await fetchServiceRates()
        if (!response) return
        const dropdown: IDropdownItem<IServiceRate>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setServiceRates(dropdown))
    }

    const getChargeCode = async () => {
        if (chargeCodesData.length > 0) return
        const response = await fetchChargeCode()
        if (!response) return
        const dropdown: IDropdownItem<IChargeCode>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setChargeCodes(dropdown))
    }

    const getWeightUnit = async () => {
        if (weightUnitsData.length > 0) return
        const response = await fetchWeightUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: d.label,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setWeightUnits(dropdown))
    }

    const getVolumeUnit = async () => {
        if (volumeUnitsData.length > 0) return
        const response = await fetchVolumeUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: d.label,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setVolumeUnits(dropdown))
    }

    const getLengthUnit = async () => {
        if (lengthUnitsData.length > 0) return
        const response = await fetchVolumeUnit()
        if (!response) return
        const dropdown: IDropdownItem<IUnit>[] = response.data.map((d) => {
            return {
                label: d.label,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setWeightUnits(dropdown))
    }

    const getContainerModes = async (transportMode: string) => {
        const response = await fetchContainerModes(transportMode)
        if (!response) return
        const dropdown: IDropdownItem<ITransportMode>[] = response.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setContainerModes(dropdown))
    }
    const getAllContainerModes = async () => {
        const response1 = await fetchContainerModes('SEA')
        const response2 = await fetchContainerModes('AIR')
        if (!response1) return
        if (!response2) return
        const dropdown1: IDropdownItem<ITransportMode>[] = response1.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        const dropdown2: IDropdownItem<ITransportMode>[] = response2.data.map(
            (d) => {
                return {
                    label: d.code + ' - ' + d.label,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setAllContainerModes([...dropdown1, ...dropdown2]))
    }

    const getUnlocos = async () => {
        if (unlocodesData.length > 0) return
        const response = await fetchUnlocos()
        if (!response) return
        const dropdown: IDropdownItem<IUnlocodes>[] = response.data.map((d) => {
            return {
                label: d.locode,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setUnlocodes(dropdown))
    }

    const getOrganisations = async () => {
        if (organisationData.length > 0) return
        const response = await fetchOrganisations('')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = response.data.map(
            (d) => {
                return {
                    label: d.name,
                    value: d.code,
                    additionalData: d,
                }
            },
        )
        dispatch(setOrganisations(dropdown))
    }

    const getOrganisationCategories = async () => {
        if (organisationData.length > 0) return
        const response = await fetchOrganisationCategories()
        if (!response) return
        const dropdown: IDropdownItem<IOrganisationCategories>[] =
            response.data.map((d) => {
                return {
                    label: d.label,
                    value: d.code,
                }
            })
        dispatch(setOrganisationCategories(dropdown))
    }

    const getJobCategories = async () => {
        if (jobCategoriesData.length > 0) return
        const response = await fetchJobCategories()
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = (
            response as any
        ).data.map((d: { name: any; code: any }) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setOrganisations(dropdown))
    }

    const getShipperOrganisations = async () => {
        if (shipperOrganisationData.length > 0) return
        const response = await fetchOrganisations('shippers')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = (
            response as any
        ).data.map((d: any) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setShipperOrganisations(dropdown))
    }

    const getAgentOrganisations = async () => {
        if (agentOrganisationData.length > 0) return
        const response = await fetchOrganisations('agents')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = (
            response as any
        ).data.map((d: any) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setAgentOrganisations(dropdown))
    }

    const getConsigneeOrganisations = async () => {
        if (consigneeOrganisationData.length > 0) return
        const response = await fetchOrganisations('consignees')
        if (!response) return
        const dropdown: IDropdownItem<IOrganisation>[] = (
            response as any
        ).data.map((d: any) => {
            return {
                label: d.name,
                value: d.code,
                additionalData: d,
            }
        })
        dispatch(setConsigneeOrganisations(dropdown))
        dispatch(setJobCategory(dropdown))
    }

    const getBookingStatuses = async () => {
        if (bookingStatusesData.length > 0) return
        const response = await fetchBookingStatuses()
        if (!response) return
        const dropdown: IDropdownItem<IBookingStatus>[] = response.data.map(
            (d) => {
                return {
                    label: d.label,
                    value: d.code,
                }
            },
        )
        dispatch(setBookingStatuses(dropdown))
    }

    return {
        getCurrency,
        getRoles,
        getContainerType,
        getTransportModes,
        getShippingRates,
        getServiceRates,
        getWeightUnit,
        getVolumeUnit,
        getLengthUnit,
        getContainerModes,
        getUnlocos,
        getChargeCode,
        getBranches,
        getAllContainerModes,
        getShipperOrganisations,
        getOrganisations,
        getConsigneeOrganisations,
        getAgentOrganisations,
        getCountries,
        getStates,
        getCities,
        getJobCategories,
        getBookingStatuses,
        getOrganisationCategories,
        consigneeOrganisationData,
        rolesData,
        containerModeData,
        currencyData,
        shippingRatesData,
        serviceRatesData,
        containerTypeData,
        weightUnitsData,
        volumeUnitsData,
        lengthUnitsData,
        transportModesData,
        unlocodesData,
        chargeCodesData,
        branchesData,
        allContainerModeData,
        organisationData,
        shipperOrganisationData,
        agentOrganisationData,
        countriesData,
        citiesData,
        statesData,
        jobCategoriesData,
        bookingStatusesData,
        organisationCategoriesData,
    }
}

export default useDropdown
