export const endpoints = {
    login: 'Authentication',
    quotations: 'quotations',
    users: {
        data: 'users',
        suspend: 'users/suspend',
        deactive: 'users/deactivate',
        activate: 'users/activate',
    },
    roles: 'roles',
    branches: 'branches',
    containerTypes: 'container_types',
    currencies: 'currencies',
    transportMode: 'transport_modes',
    shippingRates: 'shipping_rates',
    serviceRates: 'service_rates',
    units: 'units',
    unlocodes: 'unlocodes',
    chargeCodes: 'charge_codes',
    countries: 'countries',
    states: 'states',
    cities: 'cities',
    bookings: 'bookings',
    organisations: 'organisations',
    contacts: 'contacts',
    job_categories: 'job_categories',
    bookingStatuses: 'bookings/statuses',
    organisationCategories: 'organisation_categories',
}
