import Overlay from '@components/overlay/overlay.component'
import { IUseOverlay } from '@components/overlay/overlay.service'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { IServiceRate } from 'repository/interface/service-rate.interface'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'

const ServiceRatesOverlay = ({
    filterOverlayService,
    dropdownServiceRates = [],
    onChange,
}: {
    filterOverlayService: IUseOverlay
    dropdownServiceRates: IDropdownItem<IServiceRate>[]
    onChange: (val: IServiceRate | null) => void
}) => {
    return (
        <Overlay
            overlayService={filterOverlayService}
            className="flex absolute left-0 m-0 mt-[10px] !w-[250px]"
        >
            <div className={`w-full p-2`}>
                <div className="text-size-S mb-1">Select Template</div>
                <FormDropdown<IServiceRate>
                    name="serviceTemplateCode"
                    additionalOnClick={(data) => {
                        filterOverlayService.closeOverlayHandling()
                        const dt = data as IDropdownItem<IServiceRate>
                        onChange(dt.additionalData || null)
                    }}
                    options={dropdownServiceRates}
                    parentDivClassName="mb-0"
                    isSearchable={true}
                />
            </div>
        </Overlay>
    )
}

export default ServiceRatesOverlay
