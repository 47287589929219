import { useState, useEffect } from 'react'
import { useFormik } from 'formik'

import {
    IContactList,
    IEDocs,
    Ilabel,
} from '../../organisation-details.interface'
import {
    createOrganisationContact,
    createOrganisationEDocs,
    deleteOrganisationEDoc,
    getOrganisationContactData,
    getOrganisationDocumentTypes,
    getOrganisationEDocs,
    updateOrganisationContact,
    updateOrganisationEDoc,
} from 'pages/organisation/organisation.repository'
import { useParams } from 'react-router-dom'
import { IDropdown } from '@components/dropdown/dropdown.interface'

export const useEDocumentationListService = () => {
    const { id = 'not found' } = useParams()

    const [docListData, setDocListData] = useState<IEDocs[]>([])
    const [docDetail, setDocDetail] = useState<IEDocs>()
    const [documentTypes, setDocumentTypes] = useState([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        loadDocList()
        loadDocumentTypes()
        return () => {
            setDocListData([])
        }
    }, [])

    const formikOrgDocDetail = useFormik<any>({
        initialValues: {} as IEDocs,
        onSubmit: async (values) => {
            if (id) {
                const res = await updateOrganisationEDoc(id, values)
                if (res) {
                    loadDocList()
                }
            }
        },
        enableReinitialize: true,
    })

    const formikOrgCreateDoc = useFormik<IEDocs>({
        initialValues: {} as IEDocs,
        onSubmit: async (values) => {
            if (id) {
                const payload = {
                    document_type: values?.document_type?.code,
                    description: values?.description,
                    filename: 'SLI_XYZ.ppt',
                    path: 'https://www.someurl.com/fi   les/SLI_XYZ.ppt',
                }
                const res = await createOrganisationEDocs(id, payload)
                if (res) {
                    loadDocList()
                }
            }
        },
        enableReinitialize: true,
    })

    const loadDocList = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationEDocs(id)
            setDocListData(response.data)

            if (response?.data?.length > 0) {
                formikOrgDocDetail.setValues(response.data?.[0])
                setDocDetail(response.data?.[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const loadDocumentTypes = async () => {
        try {
            setLoading(true)
            const response = await getOrganisationDocumentTypes()
            const transformedData: any = response.data?.map((type: any) => ({
                value: type.code,
                label: type.label,
            }))
            setDocumentTypes(transformedData)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    const handleDocumentationDetail = (data: IEDocs) => {
        setDocDetail(data)
        formikOrgDocDetail.setValues(data)
    }

    const handleDuplicate = async () => {
        if (id && docDetail) {
            const res = await createOrganisationEDocs(id, docDetail)
            if (res) {
                loadDocList()
            }
        }
    }

    const deleteDoc = async (dataId: string) => {
        try {
            setLoading(true)
            const response = await deleteOrganisationEDoc(id, dataId)
            const filterData = docListData.filter(
                (data) => data.code !== dataId,
            )
            setDocListData(filterData)

            if (filterData?.length > 0) {
                formikOrgDocDetail.setValues(filterData[0])
                setDocDetail(filterData[0])
            }

            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            console.error(errorMessage)
        }
    }

    return {
        docListData,
        docDetail,
        formikOrgDocDetail,
        formikOrgCreateDoc,
        handleDocumentationDetail,
        documentTypes,
        deleteDoc,
        handleDuplicate,
    }
}
