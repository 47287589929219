import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserData } from 'repository/user.repository'
import { useAppDispatch } from 'store'
import {
    filterSelector,
    setFetchParam,
    setFilterSearchUser,
    setPageNumber,
    // setSelectedStatus,
    setSelectedTabItem,
    setSelectedTabItemHelper,
    setTabItems,
    setUserData,
    tabItemsSelector,
    tabStatusFilterSelector,
    userDataSelector,
    userMeta,
    usersFetchParamSelector,
    usersSelectedTabItemHelperSelector,
    usersSelectedTabItemSelector,
} from './user-access.slice'
import { useSelector } from 'react-redux'
import { ITabItem } from '@components/tab/tab.interface'
// import { Toast } from '@components/toast/toast.component'
import useOverlay from '@components/overlay/overlay.service'
import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'

const useUserAccess = () => {
    // initial
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const fetchParam = useSelector(usersFetchParamSelector)
    const userData = useSelector(userDataSelector)
    const userDataMeta = useSelector(userMeta)
    const filter = useSelector(filterSelector)
    const tabFilter = useSelector(tabStatusFilterSelector)
    const tabItems = useSelector(tabItemsSelector)
    const selectedTabItem = useSelector(usersSelectedTabItemSelector)
    const selectedTabItemHelper = useSelector(
        usersSelectedTabItemHelperSelector,
    )
    const dropdownService = useDropdown()
    const [loading, setLoading] = useState<boolean>(false)
    const [isInit, setIsInit] = useState<boolean>(true)
    // useEffect when filter applied

    useEffect(() => {
        dropdownService.getRoles()
        dropdownService.getBranches()
        dropdownService.getCountries()
        dropdownService.getJobCategories()
    }, [])

    useEffect(() => {
        if (selectedTabItem.value === selectedTabItemHelper) return
        if (selectedTabItem && isInit) {
            const selectedItem = selectedTabItem.value
            const status = selectedItem === 'ALL' ? undefined : selectedItem
            loadData(
                {
                    // ...fetchParam,
                    page: 1,
                    size: 50,
                    status,
                    sort: 'name',
                    order: 'asc',
                    search: fetchParam.search ?? '',
                },
                false,
            )
        }
    }, [selectedTabItem])

    useEffect(() => {
        if (isInit && userData.length > 0) return
        const selectedItem = selectedTabItem.value
        const mode = selectedItem === 'ALL' ? undefined : selectedItem
        loadData(
            {
                // ...fetchParam,
                page: 1,
                size: 50,
                status: mode,
                sort: 'name',
                order: 'asc',
                search: fetchParam.search ?? '',
            },
            isInit,
        )
        setIsInit(false)
    }, [fetchParam])

    const handleSearch = (values: string) => {
        console.log()
        const fParams = { size: 50, page: 1, search: values }
        dispatch(setFetchParam(fParams))
    }
    // set data filter from tab
    const setTabFilter = (data: ITabItem) => {
        dispatch(setSelectedTabItem(data))
    }

    // set page
    const setPageData = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber))
    }

    const setSearch = (values: string) => {
        dispatch(setFilterSearchUser(values))
    }

    // overlay
    const filterOverlayService = useOverlay()

    // Get Data Function
    const loadData = async (
        params: IFetchParams,
        isRequestTabItems: boolean,
    ) => {
        const _params = { ...params }

        try {
            setLoading(true)
            // try {
            // const filterByStatus = tabFilter.value ?? 'Active'
            const response = await getUserData(_params, true)
            if (!response) {
                setLoading(false)
                dispatch(
                    setUserData({
                        data: [],
                        meta: {
                            start: 0,
                            size: 0,
                            page: 0,
                            data_count: 0,
                            total_data_count: 0,
                            total_page: 0,
                        },
                    }),
                )
                return
            }
            if (isRequestTabItems) {
                getTabItems(_params)
            }
            dispatch(setUserData(response))
            dispatch(
                setSelectedTabItemHelper((params.status as string) ?? 'ALL'),
            )
            // } catch (e) {
            //     Toast({
            //         header: 'Failed!',
            //         message: 'Failed to get user data',
            //         type: 'error',
            //     })
            // }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            const errorMessage = typeof e !== 'string' ? 'Something wrong' : e
            // setErrorMessage(errorMessage)
            console.error(errorMessage)
        }
    }
    const getTabItems = async (fParams: IFetchParams) => {
        let totalActive = 0
        let totalInactive = 0
        let totalSuspend = 0
        let totalData = 0
        const responseData = await getUserData({
            ...fParams,
            size: 1,
            status: undefined,
        })
        const responseActive = await getUserData({
            ...fParams,
            status: 'ACTIVE',
            size: 1,
        })
        const responseSuspend = await getUserData({
            ...fParams,
            status: 'SUSPEND',
            size: 1,
        })
        const responseInactive = await getUserData({
            ...fParams,
            status: 'INACTIVE',
            size: 1,
        })

        totalData = responseData?.meta.total_data_count ?? 0
        totalActive = responseActive?.meta.total_data_count ?? 0
        totalSuspend = responseSuspend?.meta.total_data_count ?? 0
        totalInactive = responseInactive?.meta.total_data_count ?? 0

        const tItems: ITabItem[] = tabItems.map((d) => {
            let count
            if (d.value === 'ALL') count = totalData
            else if (d.value === 'ACTIVE') count = totalActive
            else if (d.value === 'SUSPEND') count = totalSuspend
            else if (d.value === 'INACTIVE') count = totalInactive
            else count = 0

            return { ...d, totalData: count }
        })
        dispatch(setTabItems(tItems))
    }
    const refreshSummary = () => {
        dispatch(
            setUserData({
                data: [],
                meta: {
                    start: 0,
                    size: 0,
                    page: 0,
                    data_count: 0,
                    total_data_count: 0,
                    total_page: 0,
                },
            }),
        )
        navigate('/manage-users')
    }

    return {
        navigate,
        setLoading,
        getTabItems,
        setTabFilter,
        setPageData,
        loadData,
        setSearch,
        refreshSummary,
        handleSearch,
        selectedTabItem,
        loading,
        userData,
        tabFilter,
        userDataMeta,
        tabItems,
        filterOverlayService,
    }
}

export default useUserAccess
