import { IMeta } from '@components/pagination/pagination.interface'
import { Toast } from '@components/toast/toast.component'
import { ITableColumn } from '@components/table/table.interface'
import { IQuotation } from 'repository/interface/quotations.interface'
import { ITabItem } from '@components/tab/tab.interface'

export const tabItemsStatic: ITabItem[] = [
    {
        label: 'All Quotes',
        key: 'status',
        value: 'all',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'SEA',
        key: 'status',
        value: 'SEA',
        className: 'brand',
        totalData: 0,
    },
    {
        label: 'AIR',
        key: 'status',
        value: 'AIR',
        className: 'brand',
        totalData: 0,
    },
]

export const quoteHeaders: ITableColumn<IQuotation>[] = [
    {
        label: 'Mode',
        accessor: 'transport_mode',
        customBuild: (data) => {
            let iconMode: string
            let labelMode: string
            switch (data) {
                case 'AIR':
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
                    break
                case 'SEA':
                    iconMode = 'ri-ship-line'
                    labelMode = 'SEA'
                    break
                default:
                    iconMode = 'ri-plane-line'
                    labelMode = 'AIR'
            }
            return (
                <div className="flex gap-2">
                    <i className={`${iconMode}`}></i> {labelMode}
                </div>
            )
        },
    },
    {
        label: 'Quote ID',
        accessor: 'quote_id',
    },
    {
        label: 'Client',
        accessor: 'customer_name',
    },
    {
        label: 'Origin',
        accessor: 'origin_location.name',
    },
    {
        label: 'Destination',
        accessor: 'destination_location.name',
    },
    {
        label: 'Container Mode',
        accessor: 'container_mode',
    },
    {
        label: 'Currency',
        accessor: 'currency.short_code',
    },
    {
        label: 'Volumetric Weight',
        accessor: 'volume',
        customBuild(data, rowData) {
            const dt = `${data} ${rowData?.volume_unit?.code}`
            return dt
        },
    },
    {
        label: 'Actual Weight',
        accessor: 'weight',
        customBuild(data, rowData) {
            const dt = `${data} ${rowData?.weight_unit?.code}`
            return dt
        },
    },
    {
        label: 'Date Created',
        accessor: 'created',
    },
    {
        label: 'Last Updated',
        accessor: 'updated',
    },
]

export const metaDummy: IMeta = {
    current_page: 1,
    last_page: 1,
    from: 1,
    to: 50,
    total_Items: 15,
    per_page: 0,
    total_page: 1,
    index_end: 15,
    index_start: 1,
}

export const handleFeatureInDevelopment = () =>
    Toast({
        header: 'Feature in Development',
        message:
            'This feature is currently under development and will be available soon!',
        type: 'info',
    })
