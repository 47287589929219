import Button from '@components/button/button.component'
import {
    IUseUserAccessForm,
    useUserAccessForm,
} from './user-access-form.service'
import { FormikProvider } from 'formik'
import './user-access-form.style.css'
import FormInput from '@components/form-input/form-input.component'
import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormTextArea from '@components/form-textarea/form-textarea.component'
import SelectModuleModal from './modal/select-module-modal.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import {
    ICities,
    ICountries,
    IStates,
} from 'repository/interface/country.interface'

export default function UserAccessForm({
    isNew,
    isProfilePage,
}: IUseUserAccessForm) {
    const { formikModule, moduleModalService, dropdownService, nav } =
        useUserAccessForm({ isNew, isProfilePage })

    const label = isNew ? 'CREATE USER' : 'UPDATE USER'
    const buttonLabel = isNew ? 'CREATE USER' : 'UPDATE USER'
    const cancelButtonLabel = isNew ? 'CANCEL CREATE' : 'CANCEL UPDATE'

    return (
        <>
            <div className={`container-global user-access`}>
                <div className="border-b themes-border-color">
                    <div className="p-4 text-size-S font-bold">{label}</div>
                </div>

                {/* Form */}
                <div className="flex sm:flex-wrap content">
                    <FormikProvider value={formikModule}>
                        <div className="w-full">
                            <div className="text-size-S font-bold mb-3">
                                User Profile
                            </div>
                            <FormInput
                                label="FULL NAME"
                                placeholder="Enter User Full Name"
                                name={'fullName'}
                            />
                            <FormInput
                                label="JOB TITLE"
                                placeholder="Enter User Job Title"
                                name={'job_title'}
                            />
                            <FormDropdown
                                name="job_category"
                                label="JOB CATEGORY"
                                options={dropdownService.jobCategoriesData}
                                dropDownIndicator={true}
                                additionalOnClick={(d) => {
                                    const value = (d as IDropdownItem<ICities>)
                                        .additionalData?.code
                                    formikModule.setFieldValue(
                                        'job_category',
                                        value,
                                    )
                                }}
                                isSearchable={true}
                            />

                            <div className="text-size-S font-bold mb-3">
                                User Account
                            </div>
                            <FormInput
                                label="EMAIL"
                                placeholder="Enter User Email"
                                name={'email'}
                            />
                            <FormInput
                                label="USERNAME"
                                placeholder="Enter Username"
                                name={'username'}
                            />

                            <div className="flex gap-3 justify-between mb-3">
                                <div className="flex-grow leading-tight t">
                                    <div className="text-size-S font-bold">
                                        ROLE
                                    </div>
                                    <div className="text-size-XS leading-tight themes-text-gray-v4">
                                        Adjust on what you would like to display
                                        and hide on the portal, disable or
                                        enable to change your portal module. You
                                        can add/edit roles in settings.
                                    </div>
                                </div>
                                <div
                                    className="flex-initial flex items-center justify-end text-size-S w-[240px] cursor-pointer"
                                    onClick={() =>
                                        moduleModalService.openModalHandling()
                                    }
                                >
                                    <i className="ri-edit-line mr-2 -mt-1" />
                                    SET UP ROLE
                                </div>
                            </div>
                            <FormDropdown
                                name="role"
                                label="Role"
                                options={dropdownService.rolesData}
                            />
                            <FormDropdown
                                dropDownIndicator={true}
                                name="branches"
                                label="Branch"
                                isMultiSelect={true}
                                isSearchable={true}
                                options={dropdownService.branchesData}
                            />
                        </div>
                        <div className="w-full">
                            <div className="text-size-S font-bold mb-3">
                                User Address (Optional)
                            </div>
                            <div className="flex gap-2 w-full">
                                <div className="w-full">
                                    <FormDropdown
                                        name="country"
                                        label="Country"
                                        options={dropdownService.countriesData}
                                        dropDownIndicator={true}
                                        additionalOnClick={(d) => {
                                            const value = (
                                                d as IDropdownItem<ICountries>
                                            ).additionalData?.code
                                            formikModule.setFieldValue(
                                                'country',
                                                value,
                                            )
                                            if (value)
                                                dropdownService.getStates(value)
                                        }}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormDropdown
                                        name="state"
                                        label="States"
                                        options={dropdownService.statesData}
                                        dropDownIndicator={true}
                                        additionalOnClick={(d) => {
                                            const value = (
                                                d as IDropdownItem<IStates>
                                            ).additionalData?.code
                                            formikModule.setFieldValue(
                                                'state',
                                                value,
                                            )
                                            if (value)
                                                dropdownService.getCities(value)
                                        }}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormDropdown
                                        name="city"
                                        label="City"
                                        options={dropdownService.citiesData}
                                        dropDownIndicator={true}
                                        additionalOnClick={(d) => {
                                            const value = (
                                                d as IDropdownItem<ICities>
                                            ).additionalData?.code
                                            formikModule.setFieldValue(
                                                'city',
                                                value,
                                            )
                                        }}
                                        isSearchable={true}
                                    />
                                </div>
                            </div>
                            <FormTextArea
                                name="address"
                                label="Address Details"
                                placeholder="Enter Address Details"
                                rows={3}
                            />

                            <div className="w-full">
                                <div className="text-size-S font-bold mb-3">
                                    User Contact (Optional)
                                </div>
                                <div className="flex gap-2 w-full">
                                    <div className="w-full">
                                        <FormInput
                                            name="mobile"
                                            label="Mobile Phone Number"
                                            placeholder="Enter Mobile Phone number"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <FormInput
                                            name="phone"
                                            label="Home Phone Number"
                                            placeholder="Enter Phone number"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full">
                                <div className="text-size-S font-bold mb-3">
                                    Emergency Contact (Optional)
                                </div>
                                <div className="flex gap-2 w-full">
                                    <div className="w-full">
                                        <FormInput
                                            name="emergency_contact_name"
                                            label="Emergency Contact Name"
                                            placeholder="Enter Contact name"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <FormInput
                                            name="emergency_contact_phone"
                                            label="Emergency Contact Phone"
                                            placeholder="Enter phone number"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormikProvider>
                </div>

                {/* Button */}
                <div className="flex justify-between p-4 border-t">
                    <Button
                        onClick={() => {
                            nav(-1)
                        }}
                        type="button"
                        label={cancelButtonLabel}
                    />
                    <Button
                        type="button"
                        onClick={async () => {
                            formikModule.submitForm()
                        }}
                        variant="brand"
                        label={buttonLabel}
                    />
                </div>
            </div>

            <SelectModuleModal
                formikModule={formikModule}
                modalService={moduleModalService}
                submitHandling={(data) => {
                    console.log(data)
                }}
            />
        </>
    )
}
