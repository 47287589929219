import { IRole } from 'repository/interface/role.interface'
import { IUserActivityLogs } from './user-access.interface'

export const OptionsUserLevel: IRole[] = [
    { label: 'ADMIN USER', value: 'admin' },
    { label: 'SUPER USER', value: 'super' },
    { label: 'REGULAR USER', value: 'regular' },
]

export const dummyCity: string[] = [
    'Ambon',
    'Balikpapan',
    'Banda Aceh',
    'Bandar Lampung',
    'Bandung',
    'Banjar',
    'Banjarbaru',
    'Banjarmasin',
    'Batam',
    'Batu',
    'Bau-Bau',
    'Bekasi',
    'Bengkulu',
    'Bima',
    'Binjai',
    'Bitung',
    'Blitar',
    'Bogor',
    'Bontang',
    'Bukittinggi',
    'Cimahi',
    'Cirebon',
    'Denpasar',
    'Depok',
    'Dumai',
    'Gorontalo',
    'Gunungsitoli',
    'Jakarta',
    'Jambi',
    'Jayapura',
    'Kediri',
    'Kendari',
    'Kotamobagu',
    'Kupang',
    'Langsa',
    'Lhokseumawe',
    'Lubuklinggau',
    'Madiun',
    'Magelang',
    'Makassar',
    'Malang',
    'Manado',
    'Mataram',
    'Medan',
    'Metro',
    'Meulaboh',
    'Mojokerto',
    'Padang',
    'Padang Panjang',
    'Padangsidimpuan',
    'Pagar Alam',
    'Palangka Raya',
    'Palembang',
    'Palopo',
    'Palu',
    'Pangkal Pinang',
    'Parepare',
    'Pariaman',
    'Pasuruan',
    'Payakumbuh',
    'Pekalongan',
    'Pekanbaru',
    'Pematangsiantar',
    'Pontianak',
    'Prabumulih',
    'Probolinggo',
    'Sabang',
    'Salatiga',
    'Samarinda',
    'Sawahlunto',
    'Semarang',
    'Serang',
    'Sibolga',
    'Singkawang',
    'Solok',
    'Sorong',
    'Subulussalam',
    'Sukabumi',
    'Sungai Penuh',
    'Surabaya',
    'Surakarta',
    'Tangerang',
    'Tangerang Selatan',
    'Tanjung Balai',
    'Tanjung Pinang',
    'Tarakan',
    'Tasikmalaya',
    'Tebing Tinggi',
    'Tegal',
    'Ternate',
    'Tidore Kepulauan',
    'Tomohon',
    'Tual',
    'Yogyakarta',
]

export const dummyCountry: string[] = ['Indonesia']

export const dummyOriginType: string[] = ['CONDIGNEE', 'VIP']

export const dummyActivittyUser: IUserActivityLogs[] = [
    {
        timestamp: '2024-12-20 15:00:00',
        module: 'Booking',
        action: 'Create Booking',
        description: 'Created new booking',
    },
    {
        timestamp: '2024-12-21 16:00:00',
        module: 'Quotation',
        action: 'Edit Quotation',
        description: 'Edit quotations no 0000001041',
    },
    {
        timestamp: '2024-12-22 17:00:00',
        module: 'Our Service',
        action: 'Submit Requirement',
        description: 'Submit new requirement',
    },
]
