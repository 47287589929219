var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as XLSX from "xlsx";
import "jspdf-autotable";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logoLoglines from "@assets/img/logos/LoglinesLogo.png";
var getNestedValue = function (accessor, obj) {
    var data = obj;
    if (accessor.includes(".")) {
        var accessorArray = accessor.split(".");
        for (var _i = 0, accessorArray_1 = accessorArray; _i < accessorArray_1.length; _i++) {
            var key = accessorArray_1[_i];
            data = data === null || data === void 0 ? void 0 : data[key];
        }
    }
    else {
        data = obj[accessor];
    }
    return data;
};
export var generateSimpleReport = function (_a) {
    var headers = _a.headers, data = _a.data, fileName = _a.fileName, title = _a.title;
    var getJsonData = function () {
        var jsonData = data.map(function (d) {
            var json = {};
            headers.forEach(function (r) {
                json[r.label] = getNestedValue(r.accessor, d);
            });
            return json;
        });
        return jsonData;
    };
    var exportToXLS = function () {
        var jsonData = getJsonData();
        var worksheet = XLSX.utils.json_to_sheet(jsonData);
        var workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, fileName + ".xlsx");
    };
    var exportToPDF = function (_a) {
        var jsPDFOptions = _a.jsPDFOptions, options = _a.options;
        var _header = [headers.map(function (d) { return d.label; })];
        var _data = data.map(function (row) {
            var dt = headers.map(function (d) {
                return getNestedValue(d.accessor, row);
            });
            return dt;
        });
        // JSpdf
        var doc = new jsPDF(jsPDFOptions);
        // Add logo
        doc.addImage(logoLoglines, "PNG", 10, 10, 50, 30); // Adjust position and size as needed
        doc.text("".concat(title), 80, 30);
        var logoSpace = 50;
        autoTable(doc, __assign({ head: _header, body: _data, startY: logoSpace }, options));
        doc.save("".concat(fileName, ".pdf"));
    };
    return {
        exportToXLS: exportToXLS,
        exportToPDF: exportToPDF,
    };
};
