import { ITabItem } from '@components/tab/tab.interface'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { IMeta2 } from '@components/pagination/pagination.interface'
import { IFetchParams, IResponseData } from 'common/common.interface'
import { IBooking } from 'repository/interface/booking.interface'
import { tabItemStatic } from './bookings.static'

interface IBookingSlice {
    data: IBooking[]
    meta: IMeta2
    fetchParams: IFetchParams
    exportFetchParams: IFetchParams
    tabItems: ITabItem[]
    tabItemSelected: ITabItem
    isLoading: boolean
}
export const initialState: IBookingSlice = {
    data: [],
    tabItemSelected: tabItemStatic[0],
    tabItems: tabItemStatic,
    meta: {
        start: 1,
        size: 50,
        page: 1,
        data_count: 50,
        total_data_count: 50,
        total_page: 1,
    },
    fetchParams: {
        recountingData: true,
        status: 'OPEN',
    },
    exportFetchParams: {},
    isLoading: false,
}

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            const data = action.payload
            return { ...state, isLoading: data }
        },
        setFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, fetchParams: data }
        },
        setExportFetchParam(state, action: PayloadAction<IFetchParams>) {
            const data = action.payload
            return { ...state, setExportFetchParam: data }
        },
        setData(state, action: PayloadAction<IResponseData<IBooking[]>>) {
            const data = action.payload
            return { ...state, data: data.data, meta: data.meta }
        },
        setTabItems(state, action: PayloadAction<ITabItem[]>) {
            const data = action.payload
            return { ...state, tabItems: data }
        },
        setSelectedTabItem(state, action: PayloadAction<ITabItem>) {
            const data = action.payload
            const fetchParam = state.fetchParams
            return {
                ...state,
                fetchParams: {
                    ...fetchParam,
                    status: data.value,
                    recountingData: false,
                },
                tabItemSelected: data,
            }
        },
    },
})

// these all the variables used for selector
export const bookingFetchParamSelector = (state: RootState) =>
    state.booking.fetchParams || {}
export const bookingExportFetchParamSelector = (state: RootState) =>
    state.booking.exportFetchParams || {}
export const bookingDataSelector = (state: RootState) =>
    state.booking.data || []
export const bookingMetaSelector = (state: RootState) =>
    state.booking.meta || {}
export const bookingTabItemsSelector = (state: RootState) =>
    state.booking.tabItems
export const bookingSelectedTabItemSelector = (state: RootState) =>
    state.booking.tabItemSelected
export const bookingIsLoading = (state: RootState) => state.booking.isLoading

// all actions
export const {
    setData,
    setSelectedTabItem,
    setTabItems,
    setFetchParam,
    setIsLoading,
} = bookingSlice.actions

export default bookingSlice.reducer
