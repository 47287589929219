import {
    IAddressList,
    IContactList,
} from 'pages/organisation/organisation-details/organisation-details.interface'
import * as Yup from 'yup'

export interface ICreateOrgValidation {
    org: {
        organisationCode: string
        fullName: string
        category: string
        email?: string
        emailAccounting?: string
        phoneNumber?: string
        mobileNumber?: string
        fax?: string
        websiteUrl?: string
        language: string
        remark?: string
        govermentTaxType: string
        govermentTaxNumber: string
        isActive: boolean
        isreceivables: boolean
    }
    address: IAddressList[]
    contact: IContactList[]
}

export const createOrgValidation = Yup.object({
    org_name: Yup.string().required('Name is required'),
    category: Yup.string().required('Organization category is required'),
    email_1: Yup.string().email('Invalid email').nullable(),
    email_2: Yup.string().email('Invalid email').nullable(),
    phone_no: Yup.string().nullable(),
    mobile_no: Yup.string().nullable(),
    tax_type: Yup.string().required('Tax type is required'),
    tax_number: Yup.string().required('Tax Number is required'),
    language: Yup.string().required('Language is required'),
    // Boolean fields can have default values, validation not required
    is_shipper: Yup.boolean(),
    is_consignee: Yup.boolean(),
    is_agent: Yup.boolean(),
    is_customer: Yup.boolean(),
    is_transport: Yup.boolean(),
    is_warehouse: Yup.boolean(),
    is_carrier: Yup.boolean(),
    is_supplier: Yup.boolean(),
    is_notify: Yup.boolean(),
    is_vip: Yup.boolean(),
    is_services: Yup.boolean(),
    is_sales: Yup.boolean(),
    is_store: Yup.boolean(),

    consignment_delivery_address: Yup.string().required(
        'Consigment Delivery Address is required',
    ),
    name: Yup.string().required('Address name is required'),
    street: Yup.string().required('Street is required'),
    street_2: Yup.string().nullable(),
    city: Yup.object({
        code: Yup.string().required('City code is required'),
    }),
    state: Yup.object({
        code: Yup.string().required('State code is required'),
    }),
    country: Yup.object({
        code: Yup.string().required('Country code is required'),
    }),
    post_code: Yup.string().required('Post code is required'),
    unlocode: Yup.string().required('Unloco is required'),
})

export const createOrgInitialValue: ICreateOrgValidation = {
    org: {
        organisationCode: '',
        fullName: '',
        category: '',
        language: '',
        govermentTaxType: '',
        govermentTaxNumber: '',
        isActive: true,
        isreceivables: true,
    },
    address: [],
    contact: [],
}
