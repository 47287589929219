import { IFetchParams } from 'common/common.interface'
import useDropdown from 'common/dropdown/dropdown.service'
import { useFormik } from 'formik'
import { useEffect } from 'react'

interface IOrganisationFilter
    extends Pick<
        IFetchParams,
        'category' | 'city' | 'country' | 'organisation_type' | 'fileExportType'
    > {}

export const initialOrganisationFilter: IOrganisationFilter = {
    fileExportType: 'xls',
    category: '',
    city: '',
    organisation_type: '',
}

const useOrganisationFilter = (
    onFilterSubmit: (data: IFetchParams) => void,
    isGenerateReport?: boolean,
) => {
    const dropdown = useDropdown()
    const formik = useFormik<IOrganisationFilter>({
        initialValues: initialOrganisationFilter,
        onSubmit: (values) => {
            console.log(values)
            generateFilter(values)
        },
    })

    useEffect(() => {
        dropdown.getCities()
        dropdown.getCountries()
        dropdown.getOrganisationCategories()
    }, [])

    const generateFilter = async (values: IOrganisationFilter) => {
        let params: IFetchParams = { ...values, size: 50, page: 1 }
        if (isGenerateReport) params = { ...values, size: 2000, page: 1 }
        await onFilterSubmit(params)
    }

    return { formik, dropdown }
}

export default useOrganisationFilter
