import { ISTColumn } from '@components/simple-table/simple-table.interface'
import { ITabItem } from '@components/tab/tab.interface'
import {
    IAccounts,
    IAddressList,
    IContactList,
    IEDocs,
    IEDocumentations,
    INotes,
} from './organisation-details.interface'
import Checkbox from '@components/checkbox/checkbox.component'
import ActionSimpleTableOverlay from './components/action-simple-table.overlay.component'
import { ISteps } from '@components/progression-step/progression-step.interface'

export const organisationDetailTabHeaders: ITabItem[] = [
    {
        label: 'Details',
        key: 'status',
        value: 'details',
    },
    {
        label: 'Address List',
        key: 'status',
        value: 'addressList',
    },
    {
        label: 'Contact List',
        key: 'status',
        value: 'contactList',
    },
    {
        label: 'Notes',
        key: 'status',
        value: 'notes',
    },
    {
        label: 'Accounts',
        key: 'status',
        value: 'accounts',
    },
    {
        label: 'eDocumentations',
        key: 'status',
        value: 'eDocumentations',
    },
]

export const contactTypeHeaders = [
    {
        label: 'Shipper',
        selected: 'is_shipper',
    },
    {
        label: 'Consignee',
        selected: 'is_consignee',
    },
    {
        label: 'Agent',
        selected: 'is_agent',
    },
    {
        label: 'Customer',
        selected: 'is_customer',
    },
    {
        label: 'Transport',
        selected: 'is_transport',
    },
    {
        label: 'Warehouse',
        selected: 'is_warehouse',
    },
    {
        label: 'Carrier',
        selected: 'is_carrier',
    },
    {
        label: 'Supplier',
        selected: 'is_supplier',
    },
    {
        label: 'Notify',
        selected: 'is_notify',
    },
    {
        label: 'VIP',
        selected: 'is_vip',
    },
    {
        label: 'Services',
        selected: 'is_services',
    },
    {
        label: 'Sales',
        selected: 'is_sales',
    },
    {
        label: 'Store/Shop',
        selected: 'is_store',
    },
]

export const addressListHeaders: ISTColumn<any>[] = [
    {
        label: 'Address Name',
        accessor: 'name',
    },
    {
        label: 'Unloco',
        accessor: 'unlocode',
    },
    {
        label: 'Country/Region',
        accessor: 'country',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData?.country) {
                const name = rowData.country.name
                return <>{name}</>
            } else return <></>
        },
    },
    {
        label: 'State',
        accessor: 'state',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData?.state) {
                const name = rowData.state.name
                return <>{name}</>
            } else return <></>
        },
    },
    {
        label: 'City',
        accessor: 'city',
        customBuild: (data, x, y, z, rowData) => {
            if (rowData?.city) {
                const name = rowData.city.name
                return <>{name}</>
            } else return <></>
        },
    },
    {
        label: 'Postcode',
        accessor: 'post_code',
    },
    {
        label: 'Main Address',
        accessor: 'is_main',
        customBuild: (data, x, y, z, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    disabled
                    isChecked={rowData?.is_main || false}
                />
            )
        },
    },
    {
        label: 'Active Address',
        accessor: 'is_active',
        customBuild: (data, x, y, z, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    disabled
                    isChecked={rowData?.is_active || false}
                />
            )
        },
    },
    // {
    //     label: '',
    //     accessor: 'id',
    //     customBuild: (data, x, y, customBuildParam4, rowData) => {
    //         if (!customBuildParam4 || !rowData) {
    //             console.error('customBuildParam4 or rowData is undefined')
    //             return <></>
    //         }
    //         const customFunctions = customBuildParam4(rowData)
    //         return (
    //             <ActionSimpleTableOverlay
    //                 content={'addressList'}
    //                 functions={{
    //                     func1: customFunctions.func1,
    //                     func2: customFunctions.func2,
    //                 }}
    //             />
    //         )
    //     },
    // },
]

export const contactListHeaders: ISTColumn<IContactList>[] = [
    { label: 'Contact Name', accessor: 'name' },
    { label: 'Job Title', accessor: 'job_title' },
    {
        label: 'Job Category',
        accessor: 'job_category',
        customBuild: (data, x, y, z, rowData) => {
            return <>{rowData?.job_category?.name}</>
        },
    },
    { label: 'Phone Number', accessor: 'phone' },
    { label: 'Email', accessor: 'email' },
    {
        label: 'Active',
        accessor: 'is_active',
        customBuild: (data, x, y, z, rowData) => {
            return (
                <Checkbox
                    onChecked={() => {}}
                    isChecked={rowData?.is_active}
                    disabled
                />
            )
        },
    },
    // {
    //     label: '',
    //     accessor: 'contactName',
    //     customBuild: (data, x, y, customBuildParam4, rowData) => {
    //         if (!customBuildParam4 || !rowData) {
    //             console.error('customBuildParam4 or rowData is undefined')
    //             return <></>
    //         }
    //         const customFunctions = customBuildParam4(rowData)
    //         return (
    //             <ActionSimpleTableOverlay
    //                 content={'contactList'}
    //                 functions={{
    //                     func1: customFunctions.func1,
    //                     func2: customFunctions.func2,
    //                 }}
    //             />
    //         )
    //     },
    // },
]

export const notesHeaders: ISTColumn<INotes>[] = [
    { label: 'Note Category', accessor: 'category' },
    { label: 'Note Text', accessor: 'text' },
    { label: 'Created By', accessor: 'created' },
    { label: 'Source', accessor: 'source' },
    { label: 'Creation Date', accessor: 'created' },
    { label: 'Last Updated', accessor: 'updated' },
]

export const accountHeaders: ISTColumn<IAccounts>[] = [
    {
        label: 'Account Name',
        accessor: 'name',
    },
    {
        label: 'Account Number',
        accessor: 'account_number',
    },
    {
        label: 'Routing Number',
        accessor: 'routing_number',
    },
    {
        label: 'Remark',
        accessor: 'remark',
    },
    // {
    //     label: 'Currency',
    //     accessor: 'defaultAcc',
    //     customBuild: (data, x, y, z, rowData) => {
    //         return (
    //             <Checkbox
    //                 onChecked={() => {}}
    //                 isChecked={rowData?.defaultAcc}
    //                 disabled
    //             />
    //         )
    //     },
    // }
]

export const eDocumentationHeaders: ISTColumn<IEDocs>[] = [
    {
        label: 'Date Added',
        accessor: 'created',
    },
    {
        label: 'File Name',
        accessor: 'filename',
    },
    {
        label: 'Document Type',
        accessor: 'document_type',
    },
    {
        label: 'Desc.',
        accessor: 'description',
    },
]

// segment create org
export const stepCreateOrg: ISteps[] = [
    {
        textIcon: '01',
        label: 'Details',
        value: 'org',
    },
    {
        textIcon: '02',
        label: 'Address',
        value: 'address',
    },
    {
        textIcon: '03',
        label: 'Additional Contacts',
        value: 'contact',
    },
]
