import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import AppRoute from 'AppRoute'
import './config/config-i18n.service'

// styling
import 'remixicon/fonts/remixicon.css'
import './index.css'
import './layout/layout.style.css'
import '@themes/common.themes.css'
import '@themes/dark-sapphire-blue.themes.css'
import '@themes/bright-sky-blue.themes.css'
import '@themes/dark-teal.themes.css'
import { Suspense } from 'react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <HashRouter>
                <Suspense>
                    <AppRoute />
                    <ToastContainer />
                </Suspense>
            </HashRouter>
        </PersistGate>
    </Provider>,
)

reportWebVitals()
