import Button from '@components/button/button.component'
import { IButton } from '@components/button/button.interface'
import Modal from '@components/modal/modal.component'
import { IUseModal } from '@components/modal/modal.service'
import { Toast } from '@components/toast/toast.component'
import { IToastProps } from '@components/toast/toast.interface'

const RemoveModal = ({
    modalService,
    data,
    type,
    onClick,
}: {
    modalService: IUseModal
    data: string
    type:
        | 'nonActiveOrg'
        | 'removeAddress'
        | 'removeContact'
        | 'removeNote'
        | 'reactivateOrg'
        | 'removeDoc'
        | 'removeAccount'
    onClick: () => void
}) => {
    let header: string = ''
    let message: string = ''
    let propertyButton1: IButton = {
        variant: 'default',
        label: '',
        onClick: () => {},
    }
    let toastProperty: IToastProps

    switch (type) {
        case 'nonActiveOrg':
            header = 'Mark Organisation as Non Active'
            message = `Are you sure you want to mark this ${data}as non-active? This action means you will no longer be able to add this organization to any activity.`
            propertyButton1 = {
                variant: 'red',
                label: 'MARK AS NON ACTIVE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Organisation Marked as Non-Active',
                message: `Organisation successfully marked as non-active.`,
                type: 'success',
            }
            break
        case 'reactivateOrg':
            header = 'Reactivate Organisation'
            message = `Are you sure you want to reactivate ${data}? This will restore its active status.`
            propertyButton1 = {
                variant: 'brand',
                label: 'REACTIVATE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Contact Reactivated',
                message: `Organisation/Contact successfully reactivated`,
                type: 'success',
            }
            break
        case 'removeAddress':
            header = 'Remove Address'
            message = `Are you sure you want to remove ${data} address? This action cannot be undone`
            propertyButton1 = {
                variant: 'red',
                label: 'REMOVE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Address Removed',
                message: `Address: ${data} successfully removed`,
                type: 'success',
            }
            break
        case 'removeContact':
            header = 'Remove Contact'
            message = `Are you sure you want to remove ${data} Contact? This action cannot be undone`
            propertyButton1 = {
                variant: 'red',
                label: 'REMOVE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Contact Removed',
                message: `Contact: ${data} successfully removed`,
                type: 'success',
            }
            break
        case 'removeNote':
            header = 'Remove Note'
            message = `Are you sure you want to remove ${data} Note? This action cannot be undone`
            propertyButton1 = {
                variant: 'red',
                label: 'REMOVE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Note Removed',
                message: `Note: ${data} successfully removed`,
                type: 'success',
            }
            break
        case 'removeDoc':
            header = 'Remove EDoc'
            message = `Are you sure you want to remove ${data} EDoc? This action cannot be undone`
            propertyButton1 = {
                variant: 'red',
                label: 'REMOVE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'EDoc Removed',
                message: `EDoc: ${data} successfully removed`,
                type: 'success',
            }
            break
        case 'removeAccount':
            header = 'Remove Account'
            message = `Are you sure you want to remove ${data} from Account ? This action cannot be undone`
            propertyButton1 = {
                variant: 'red',
                label: 'REMOVE',
                onClick: () => {},
            }
            toastProperty = {
                header: 'Account Removed',
                message: `Account: ${data} successfully removed`,
                type: 'success',
            }
            break
    }

    return (
        <Modal isModalOpen={modalService.isModalOpen} className="!w-1/3  px-0">
            <div>
                <div className="px-3 text-size-L font-bold">{header}</div>
                <div className="border-b"></div>
                <div className="p-3">{message}</div>
                <div className="border-b"></div>
                <div className="flex justify-between mt-3 px-3">
                    <Button
                        onClick={() => modalService.closeModalHandling()}
                        label={'BACK'}
                    />
                    <Button
                        onClick={() => {
                            modalService.closeModalHandling()
                            onClick()
                        }}
                        label={propertyButton1.label}
                        variant={propertyButton1.variant}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default RemoveModal
