import FormDropdown from '@components/form-dropdown/form-dropdown.component'
import FormInput from '@components/form-input/form-input.component'

const ContactListForm = () => {
    return (
        <div>
            <FormInput
                label="Contact Name"
                placeholder="Enter Contact Name"
                name="name"
                parentDivClassName="col-span-2"
            />

            <FormDropdown
                label="Contact Type"
                name="contact_type.code"
                placeholder="Select Contact Type"
                options={[
                    {
                        label: 'AGENT',
                        value: 'AGENT',
                    },
                ]}
            />

            <FormInput
                label="Job Title"
                placeholder="Enter Job Title"
                name="job_title"
                parentDivClassName="col-span-2"
                className="!mb-0"
            />

            <FormDropdown
                label="Job Category"
                name="job_category.code"
                placeholder="Select Job Category"
                options={[
                    {
                        label: 'JCA_1003',
                        value: 'JCA_1003',
                    },
                ]}
            />

            <FormInput
                label="Phone Number"
                placeholder="Enter Phone Number"
                name="phone"
                parentDivClassName="col-span-2"
            />

            <FormInput
                label="Email"
                placeholder="Enter Email"
                name="email"
                parentDivClassName="col-span-2"
            />

            <FormInput
                label="Born Date (Optional)"
                placeholder="Enter Born Date"
                name="birth_date"
                parentDivClassName="col-span-2"
            />

            <FormInput
                label="Working Address {Optional)"
                placeholder="Enter Working Address"
                name="working_address"
                parentDivClassName="col-span-2"
            />
        </div>
    )
}

export default ContactListForm
