import { ITabItem } from '@components/tab/tab.interface'
import { useEffect, useState } from 'react'
import AddressList from './components/address/address-list.component'
import { IOrganisation } from '../organisation.interface'
import { useFormik } from 'formik'
import { organisationDetailInitialValue } from 'form-validation/organisation-details.validation'
import { useModal } from '@components/modal/modal.service'
import { useNavigate } from 'react-router-dom'
import Notes from './components/notes/notes.component'
import Details from './components/details/details.component'
import ContactList from './components/contact/contact-list.component'
import { useSelector } from 'react-redux'
import { organisationDetailDataSelector } from '../organisation.slice'
import Accounts from './components/account/accounts.component'
import EDocumentationList from './components/eDocumentation/eDocumentation.component'

const useOrganisationDetail = () => {
    const navigate = useNavigate()

    // states
    const [tabFilter, setTabFilter] = useState<ITabItem>({
        label: 'Details',
        key: 'status',
        value: 'details',
        className: 'gray',
    })

    const [isEdit, setIsEdit] = useState<boolean>(true)

    const organisationDetailData = useSelector(organisationDetailDataSelector)

    // modal services
    const createOrEditModal = useModal()
    const removeModalService = useModal()
    const createOrEditContactModal = useModal()
    const removeContactModalService = useModal()
    const addContactModalService = useModal()
    const createOrEditAccountModal = useModal()
    const removeAccountModalService = useModal()

    // formik
    const formik = useFormik<IOrganisation>({
        // validationSchema: orgDetailsValidation,
        initialValues: organisationDetailInitialValue,
        onSubmit: (values) => {
            submitOrgDetails(values)
        },
    })

    // functions
    const getContent = () => {
        const contens: Record<string, JSX.Element> = {
            details: Details(),
            addressList: AddressList(),
            contactList: ContactList(),
            notes: Notes(),
            accounts: Accounts(),
            eDocumentations: EDocumentationList(),
        }
        return contens[tabFilter?.value] || <></>
    }

    // segment organisation details
    const submitOrgDetails = (data: IOrganisation) => {
        // setOrgDetails(data)
        // setIsEdit(false)
        // Toast({
        //     header: 'Organisation Updated',
        //     message: `Organisation:${data.fullName} successfully updated.`,
        //     type: 'success',
        // })
        console.log(data)
    }

    // components
    const allModal = (
        <>
            {/* <CreateOrEditAddressModal
                modalService={createOrEditModal}
                formik={formikOrgAddressDetail}
                isCreate={true}
            /> */}
            {/* <CreateOrEditContactModal
                modalService={createOrEditContactModal}
                formik={contactListFormik}
                isCreate={isCreateAddress}
            />
            <CreateOrEditAccountModal
                modalService={createOrEditAccountModal}
                formik={accountFormik}
                isCreate={isCreateAddress}
            />
            <AddContactModal
                modalService={addContactModalService}
                loadData={searchAllContact}
                data={allContact}
                setSelectContact={(data) => setSelectContact(data)}
                addContact={addContact}
            /> */}
        </>
    )

    return {
        tabFilter,
        isEdit,
        formik,
        removeModalService,
        createOrEditModal,
        createOrEditContactModal,
        removeContactModalService,
        addContactModalService,
        allModal,
        setIsEdit,
        setTabFilter,
        getContent,
        navigate,
        organisationDetailData,
    }
}

export default useOrganisationDetail
