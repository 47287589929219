/* eslint-disable no-unused-vars */
import SimpleTable from '@components/simple-table/simple-table.component'
import { IAddressList } from '../../organisation-details.interface'
import { addressListHeaders } from '../../organisation-details.static'
import Button from '@components/button/button.component'
import useAddressListService from './address-list.service'
import useOrganisationDetail from '../../organisation-details.service'
import { FormikProvider } from 'formik'
import CreateOrEditAddressModal from '../create-or-edit-address-modal.component'
import FormToggleSwitch from '@components/form-toggle-switch/form-toggle-switch.component'
import AddressListForm from './address-list-form-component'
import RemoveModal from '../remove-modal.component'
import DuplicateModal from '../duplicate-modal.component'
import { useModal } from '@components/modal/modal.service'

const AddressList = () => {
    const {
        addressListData: data,
        addressDetail,
        loading,
        handleAddressDetail,
        formikOrgAddressDetail,
        formikOrgCreateAddress,
        deleteAddress,
        handleDuplicate,
    } = useAddressListService()

    const { removeModalService } = useOrganisationDetail()

    const createOrEditModal = useModal()
    const duplicateModal = useModal()

    return (
        <>
            <div className="flex gap-x-2">
                <div
                    className={`${data?.length > 0 ? 'w-3/4' : 'w-full'} h-[calc(100vh-15.5rem)]`}
                >
                    <SimpleTable<IAddressList>
                        headers={addressListHeaders}
                        data={data || []}
                        isAddRow={true}
                        loading={loading}
                        addRowLabel="Add New Address"
                        onRowClick={(data) => handleAddressDetail(data)}
                        customBuildParam4={(data) => ({
                            // set details
                            // func1: () => func1(data.code),
                            func2: () => {
                                createOrEditModal.openModalHandling()
                            },
                            func3: () => {
                                removeModalService.openModalHandling()
                            },
                        })}
                        addRowClick={() => {
                            createOrEditModal.openModalHandling()
                        }}
                        variant="gray"
                    />
                </div>
                {data?.length > 0 && (
                    <FormikProvider value={formikOrgAddressDetail}>
                        <div className="w-1/4 border rounded h-[calc(100vh-15.5rem)] flex flex-col">
                            <div className="flex justify-between items-center sm:flex-col">
                                <div className="p-4">Address Info</div>
                                <div className="m-2 flex">
                                    <i
                                        onClick={() => {
                                            duplicateModal.openModalHandling()
                                        }}
                                        className="border-l-2 cursor-pointer p-1 ri-file-copy-line text-[1.25rem]"
                                    ></i>
                                    <i
                                        onClick={() => {
                                            removeModalService.openModalHandling()
                                        }}
                                        className="border-l-2 cursor-pointer p-1 ri-delete-bin-line text-[1.25rem] text-red-600"
                                    ></i>
                                </div>
                            </div>

                            {/* border */}
                            <div className="border-b"></div>
                            {/* border */}

                            <div className="p-4 overflow-auto">
                                <div className="flex gap-3 flex-col md:flex-row text-center mb-6">
                                    <div className="text-size-XS themes-bg-brand-v5 rounded-logistical-radius py-1 px-4 text-white">
                                        Address Detail
                                    </div>
                                    <div className="text-size-XS themes-bg-gray-v2 rounded-logistical-radius py-1 px-4">
                                        Address Capabilities
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <FormToggleSwitch
                                        label={'Main Address'}
                                        name="is_main"
                                    />
                                </div>

                                <AddressListForm />
                                <div className="flex flex-col gap-2">
                                    <Button
                                        label="SAVE CHANGES"
                                        variant="brand-inverse"
                                        className="w-full"
                                        icon="ri-save-line"
                                        onClick={() => {
                                            formikOrgAddressDetail.submitForm()
                                        }}
                                    />
                                    <Button
                                        label="DISCARD CHANGES"
                                        variant="red-inverse"
                                        className="w-full"
                                        icon="ri-save-line"
                                        onClick={() => {
                                            formikOrgAddressDetail.resetForm({
                                                values: addressDetail,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </FormikProvider>
                )}
                <RemoveModal
                    modalService={removeModalService}
                    type="removeAddress"
                    onClick={() => {
                        const id = addressDetail?.code || ''
                        deleteAddress(id)
                    }}
                    data={addressDetail?.address_name || ''}
                />
                <DuplicateModal
                    modalService={duplicateModal}
                    type="duplicateAddress"
                    onClick={handleDuplicate}
                    data={addressDetail?.address_name || ''}
                />
            </div>
            <CreateOrEditAddressModal
                modalService={createOrEditModal}
                formik={formikOrgCreateAddress}
                isCreate={true}
            />
        </>
    )
}

export default AddressList
